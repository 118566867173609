<template>
    <div class="intro-image" v-bind:class="{ 'intro-image--small-bottom' : small_bottom }" :style="style">
        <h1 class="intro-image__title" v-html="getTitle"></h1>
        <span v-if="help_text" class="intro-image__help-text">{{ help_text }}</span>
    </div>
</template>

<script>
import "./intro-image.scss"

import { mapGetters } from 'vuex'

export default {
    name: "IntroImage",
    props: {
        title: String,
        help_text: String,
        park_name: String,
        background_image: String,
        small_bottom: Boolean,
        date: Boolean,
    },
    computed: {
        ...mapGetters({
            getDate: 'getDate',
        }),
        style() {
            if (this.background_image) {
                return `background-image: url('${this.background_image}'); background-position: 50% 50%;`;
            } else {
                return "";
            }
        },
        getTitle() {
            let title = this.title;
            if (this.park_name) {
                title += ` ${this.park_name}?`;
            }
            return title;
        },
    }
}
</script>
