<template>
    <footer v-if="$store.state.general.show_footer" class="footer">
        <div class="footer__title" v-html="$store.state.general.street.general_text.footer_title"></div>
        <div class="footer__text">{{ $store.state.general.street.general_text.footer_description }}</div>
        <div class="footer__ctas">
            <a v-if="this.$store.state.general.street.general_text.footer_phonenumber" class="footer__cta" target="_blank" :href="this.$store.state.general.street.general_text.footer_phonenumber" :title="$store.state.general.street.general_text.footer_phonenumber">
                <svg class="footer__cta-image" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="100%" height="100%" rx="22" fill="white"/>
                    <path d="M19.3084 22.2139C20.054 23.7381 21.2895 24.968 22.8171 25.7066C22.9289 25.7596 23.0525 25.7825 23.1758 25.7732C23.2992 25.7639 23.4179 25.7226 23.5205 25.6534L25.7698 24.1535C25.8693 24.0872 25.9837 24.0467 26.1028 24.0358C26.2218 24.0249 26.3418 24.0438 26.4517 24.0909L30.6597 25.8943C30.8026 25.9551 30.922 26.0606 30.9997 26.195C31.0775 26.3295 31.1094 26.4856 31.0908 26.6397C30.9578 27.6805 30.45 28.6371 29.6625 29.3304C28.8749 30.0237 27.8617 30.4062 26.8125 30.4063C23.5719 30.4063 20.464 29.1189 18.1725 26.8275C15.8811 24.536 14.5938 21.4281 14.5938 18.1875C14.5938 17.1383 14.9763 16.1251 15.6696 15.3376C16.3629 14.55 17.3195 14.0422 18.3603 13.9092C18.5145 13.8906 18.6705 13.9225 18.805 14.0003C18.9394 14.078 19.045 14.1974 19.1057 14.3403L20.9107 18.552C20.9574 18.661 20.9764 18.7797 20.9661 18.8978C20.9557 19.0159 20.9164 19.1296 20.8514 19.2287L19.3568 21.5125C19.2887 21.6153 19.2485 21.7339 19.2401 21.8568C19.2316 21.9798 19.2551 22.1028 19.3084 22.2139V22.2139Z" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <span class="footer__cta-text">{{ $store.state.general.street.general_text.footer_call }}</span>
            </a>
            <a v-if="this.$store.state.general.street.general_text.footer_chat_link" class="footer__cta" target="_blank" :href="this.$store.state.general.street.general_text.footer_chat_link">
                <svg class="footer__cta-image" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="100%" height="100%" rx="22" fill="white"/>
                    <path d="M14.8559 26.9022C13.7843 25.0957 13.409 22.9602 13.8005 20.8966C14.192 18.833 15.3234 16.9834 16.9822 15.6949C18.6409 14.4065 20.713 13.7679 22.8093 13.899C24.9056 14.0302 26.8818 14.9222 28.367 16.4073C29.8522 17.8925 30.7442 19.8688 30.8754 21.9651C31.0066 24.0614 30.368 26.1335 29.0795 27.7922C27.7911 29.451 25.9414 30.5824 23.8779 30.9739C21.8143 31.3654 19.6788 30.9901 17.8723 29.9185L17.8723 29.9184L14.8934 30.7696C14.7702 30.8048 14.6397 30.8064 14.5157 30.7742C14.3916 30.7421 14.2783 30.6773 14.1877 30.5867C14.0971 30.4961 14.0323 30.3828 14.0002 30.2588C13.968 30.1347 13.9696 30.0043 14.0049 29.881L14.856 26.9021L14.8559 26.9022Z" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="footer__cta-text">{{ $store.state.general.street.general_text.footer_chat }}</span>
            </a>
            <a v-if="this.$store.state.general.street.general_text.footer_email_address" class="footer__cta" target="_blank" :href="this.$store.state.general.street.general_text.footer_email_address">
                <svg class="footer__cta-image" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="100%" height="100%" rx="22" fill="white"/>
                    <path d="M13.386 15.5396H30.6136V27.7424C30.6136 27.9328 30.5379 28.1154 30.4033 28.25C30.2687 28.3846 30.0861 28.4602 29.8958 28.4602H14.1038C13.9134 28.4602 13.7308 28.3846 13.5962 28.25C13.4616 28.1154 13.386 27.9328 13.386 27.7424V15.5396Z" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M30.6136 15.5396L21.9998 23.4355L13.386 15.5396" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="footer__cta-text">{{ $store.state.general.street.general_text.footer_mail }}</span>
            </a>
        </div>
    </footer>
</template>

<script>
import "./footer.scss"

export default {
    name: "Footer",
}
</script>
