import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { imageLoader } from "./store/functions";
import { createGtm } from "@gtm-support/vue-gtm";

/** @const {string} Configure GTM ID using VUE_APP_GTM_ID (in .env), set to "GTM-XXXXXX" for debug mode. */
const VUE_APP_GTM_ID = process.env.VUE_APP_GTM_ID;

export const functionsPlugin = {
    install(app) {
        app.config.globalProperties.toCurrency = (price, language) => {
            const priceString = price.toLocaleString(language, {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            if (priceString.endsWith(".00")) {
                return priceString.replace(".00", "");
            } else if (priceString.endsWith(",00")) {
                return priceString.replace(",00", "");
            }
            return priceString;
        };
        app.config.globalProperties.imageLoader = imageLoader;
        app.config.globalProperties.scrollChangedEvent = element => {
            return new CustomEvent("scrollChange", {
                detail: element
            });
        };
    }
};

const app = createApp(App);
app.use(functionsPlugin);
app.use(router);
app.use(store);

if (VUE_APP_GTM_ID) {
    app.use(
        createGtm({
            id: VUE_APP_GTM_ID,
            vueRouter: router,
            trackOnNextTick: false,
        })
    );
} else {
    console.debug('GTM:', 'VUE_APP_GTM_ID not set (in .env), GTM will be disabled');
}

app.mount("#app");
