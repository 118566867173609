<template>
    <div class="back">
        <svg class="back__back" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" @click="go_back()">
            <path d="M15 6.5H1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.72726 12L1 6.5L6.72726 1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
</template>

<script>
import "./back.scss"

export default {
    name: "Back",
    methods: {
        go_back: function () {
            this.$router.go(-1)
        }
    }
}
</script>
