<template>
    <div class="upsell" v-bind:class="{ 'upsell--spacing' : $store.state.order.articles.length === 0 && $store.state.tickets.upsell != null }">
        <template v-if="$store.state.tickets.upsell == null">
            <div class="upsell_slider">
                <img class="upsell__image" :src="selectedVenue.upsell_image" alt="Upsell">
                <div class="upsell__content">
                    <Intro :title="selectedVenue.upsell_title" :light=true />
                    <Button :text="selectedVenue.upsell_button_text" v-on:click="setUpsell(true)" />
                </div>
            </div>
            <More v-if="selectedVenue.upsell_more_info" :text="selectedVenue.upsell_more_info.more" :modal_id="modal_id"/>
        </template>
        <template v-if="$store.state.tickets.upsell != null">
            <IntroImage
                :title="selectedVenue.upsell_selected_title"
                :background_image="selectedVenue.upsell_image"
            />
            <TicketGroup :upsell=true :park_name="this.$store.getters.selectedVenue.park.name" />
            <More v-if="$store.state.order.upsellExpositionTickets.length > 3" :text="$store.state.general.street.general_text.more_options" :less_text="$store.state.general.street.general_text.less_options" :action="toggle_tickets" icon="more" />
        </template>
    </div>
</template>

<script>
import "./upsell.scss"
// import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import { mapGetters } from 'vuex'

import Button from "../Button/Button"
import Intro from "../Intro/Intro"
import IntroImage from "../Intro/IntroImage"
import More from "../More/More"
import TicketGroup from '@/components/Tickets/TicketGroup'

import {addExpositionPrices} from "@/store/functions"

export default {
    name: "Upsell",
    props: {
        modal_id: String,
    },
    components: {
        Intro,
        IntroImage,
        Button,
        More,
        TicketGroup,
    },
    created() {
        this.unwatchUpsell = this.$store.watch(
            (state) => state.tickets.upsell,
            (newUpsell) => {
                if (!newUpsell) {
                    return;
                }
                const upsells = this.$store.getters.selectedVenue.upsales;
                upsells.forEach(upsell => {
                    addExpositionPrices(upsell, true, this.$store);
                })
            },
        );
    },
    beforeUnmount() {
        this.unwatchUpsell();
    },
    computed: {
        ...mapGetters({
            selectedVenue: 'selectedVenue',
        })
    },
    methods: {
        async setUpsell(value) {
            this.$store.commit("setUpsell", value);
            const currentBg = this.$store.state.general.background_image;
            const newBg = this.$store.getters.selectedVenue.upsell_image;

            if (newBg && currentBg !== newBg) {
                await this.imageLoader(this.$store, newBg);
            }
        },
        toggle_tickets: function(more) {
            more.previousElementSibling.classList.toggle("ticket-group--all");
            more.classList.toggle('more--open');
        }
    },
}
</script>
