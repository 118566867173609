import { differenceInCalendarDays, parse } from "date-fns";

const getters = {
    hasUpsellTickets(state) {
        const filteredTickets = state.upsellExpositionTickets.filter(
            ticket => ticket.amount > 0
        );
        return filteredTickets.length > 0;
    },
    hasBoughtItems(state) {
        const tickets = state.expositionTickets.filter(
            ticket => ticket.amount > 0
        );
        const upsell = state.upsellExpositionTickets.filter(
            ticket => ticket.amount > 0
        );
        const articles = state.articles.filter(article => article.amount > 0);

        return tickets.length > 0 || upsell.length > 0 || articles.length > 0;
    },
    selectedTickets(state) {
        return state.expositionTickets.filter(ticket => ticket.amount > 0);
    },
    selectedUpsellTickets(state) {
        return state.upsellExpositionTickets.filter(
            ticket => ticket.amount > 0
        );
    },
    selectedArticles(state) {
        return state.articles.filter(article => article.amount > 0);
    },
    getDonation(state) {
        return state.donation;
    },
    getDonationPrice(state) {
        return state.donationPrice;
    },
    getSelectedExpositionGuids(_state, getters) {
        let guids = getters.selectedTickets.map(ticket => ticket.exposition_id);
        const unique = [...new Set(guids)];
        return unique;
    },
    getSelectedExpositionGuidsWithAmount(_state, getters) {
        let guids = [];
        getters.selectedTickets.forEach(ticket => {
            let found = guids.find(guid => guid.guid === ticket.exposition_id);
            if (found) {
                found.amount += ticket.amount;
            } else {
                guids.push({
                    guid: ticket.exposition_id,
                    amount: ticket.amount
                });
            }
        });
        return guids;
    },
    getSelectedUpsellExpositionGuids(_state, getters) {
        let guids = getters.selectedUpsellTickets.map(
            ticket => ticket.exposition_id
        );
        const unique = [...new Set(guids)];
        return unique;
    },
    getSelectedNewsletters(state) {
        return [
            state.artisNewsletter && "ARTIS nieuws",
            state.micropiaNewsletter && "Micropia nieuws",
            state.grootemuseumNewsletter && "ARTIS-Groote Museum nieuws"
        ].filter(Boolean);
    },
    getGTMItems(_state, getters) {
        const parkName = getters.selectedVenue?.park.name || "";

        const ticketItems = getters.selectedTickets.map(t => {
            const name = t.name ? String(t.name).trim() : "";
            const item_name = t.help ? `${name} | ${t.help}` : name;

            return {
                item_id: t.id,
                item_name: item_name.toLowerCase(),
                item_brand: parkName.toLowerCase(),
                item_category: "tickets_regular",
                item_category2: null,
                price: t.price.toFixed(2),
                quantity: String(t.amount),
                discount: null,
                coupon: null
            };
        });

        const upsellItems = getters.selectedUpsellTickets.map(t => {
            const name = t.name ? String(t.name).trim() : "";
            const item_name = t.help ? `${name} | ${t.help}` : name;

            return {
                item_id: t.id,
                item_name: item_name.toLowerCase(),
                item_brand: parkName.toLowerCase(),
                item_category: "tickets_upsell",
                item_category2: null,
                price: t.price.toFixed(2),
                quantity: String(t.amount),
                discount: null,
                coupon: null
            };
        });

        const articleItems = getters.selectedArticles.map(a => {
            const name = a.title ? String(a.title).trim() : "";
            const item_name = a.help_text ? `${name} | ${a.help_text}` : name;

            return {
                item_id: a.id,
                item_name: item_name.toLowerCase(),
                item_brand: parkName.toLowerCase(),
                item_category: "add_on",
                item_category2: null,
                price: a.price.toFixed(2),
                quantity: String(a.amount),
                discount: null,
                coupon: null
            };
        });

        const additionalArticleItems = getters.selectedAdditionalArticles().map(
            a => {
                const name = a.title ? String(a.title).trim() : "";
                const item_name = a.help_text
                    ? `${name} | ${a.help_text}`
                    : name;

                return {
                    item_id: a.id,
                    item_name: item_name.toLowerCase(),
                    item_brand: parkName.toLowerCase(),
                    item_category: "add_on",
                    item_category2: null,
                    price: a.price.toFixed(2),
                    quantity: String(a.amount),
                    discount: null,
                    coupon: null
                };
            }
        );

        const donation = getters.getDonation;
        const donationItemArray = parseFloat(donation?.price)
            ? [
                  {
                      item_id: donation.guid,
                      item_name: "donatie",
                      item_brand: parkName.toLowerCase(),
                      item_category: "donation",
                      item_category2: null,
                      price: donation.price,
                      quantity: "1",
                      discount: null,
                      coupon: null
                  }
              ]
            : [];

        return [
            ...ticketItems,
            ...upsellItems,
            ...articleItems,
            ...additionalArticleItems,
            ...donationItemArray
        ];
    },
    getGTMItemValue(_state, getters) {
        const items = getters.getGTMItems;
        return items.reduce((acc, item) => {
            const itemValue = parseFloat(item.price) || 0;
            const quantity = parseInt(item.quantity) || 1;
            const totalItemValue = itemValue * quantity;
            return acc + totalItemValue;
        }, 0);
    },
    getSelectedUpsellExpositionGuidsWithAmount(_state, getters) {
        let guids = [];
        getters.selectedUpsellTickets.forEach(ticket => {
            let found = guids.find(guid => guid.guid === ticket.exposition_id);
            if (found) {
                found.amount += ticket.amount;
            } else {
                guids.push({
                    guid: ticket.exposition_id,
                    amount: ticket.amount
                });
            }
        });
        return guids;
    },
    getSelectedCouponCodes(state) {
        return state?.couponCodes || [];
    },
    getSelectedPaymentOption(state) {
        return state?.paymentOption;
    },
    getAllExpositionGuids(_state, getters) {
        return getters.getSelectedExpositionGuids.concat(
            getters.getSelectedUpsellExpositionGuids
        );
    },
    hasTimePeriod: state => upsell => {
        if (upsell) {
            return state.upsellExpositionPeriodGuid.length > 0;
        } else {
            return state.expositionPeriodGuid.length > 0;
        }
    },
    getAdditionalArticles: state => selectedDate => {
        if (selectedDate) {
            let toShow = [];
            const today = new Date();
            const correctSelectedDate = parse(
                selectedDate,
                "yyyy-MM-dd",
                new Date()
            );

            state.additionalArticles.forEach(article => {
                const differenceInCalendarDay = differenceInCalendarDays(
                    correctSelectedDate,
                    today
                );
                if (differenceInCalendarDay >= article.daysBefore) {
                    toShow.push(article);
                }
            });
            return toShow;
        }
        return [];
    },
    selectedAdditionalArticles: (state, getters, rootState) => (selectedDate = rootState.datetime.date) => {
        return getters
            .getAdditionalArticles((selectedDate))
            .filter(article => article.amount > 0);
    },

    // Prices
    getDiscountPrice(state) {
        let price = 0;
        state.discounts.forEach(discount => {
            price -= discount.discountAmount;
        });
        return price;
    },
    getSubtotal(state, getters, rootState) {
        let price = 0;
        state.expositionTickets.forEach(ticket => {
            if (ticket.amount > 0 && ticket.price > 0) {
                price += ticket.price * ticket.amount;
            }
        });

        state.upsellExpositionTickets.forEach(ticket => {
            if (ticket.amount > 0 && ticket.price > 0) {
                price += ticket.price * ticket.amount;
            }
        });

        state.articles.forEach(article => {
            if (article.amount > 0 && article.price > 0) {
                price += article.price * article.amount;
            }
        });

        getters
            .selectedAdditionalArticles(rootState.datetime.date)
            .forEach(article => {
                if (article.amount > 0 && article.price > 0) {
                    price += article.price * article.amount;
                }
            });

        price += state.donationPrice;

        state.discounts.forEach(discount => {
            price -= discount.discountAmount;
        });
        return price;
    },

    // PageValidations
    isValidTicketsView(state, _getters, rootState) {
        const boughtTickets = state.expositionTickets.filter(
            ticket => ticket.amount > 0
        );
        const boughtArticles = state.articles.filter(
            article => article.amount > 0
        );

        if (rootState.general.hasDate) {
            return boughtTickets.length > 0;
        } else {
            return boughtArticles.length > 0;
        }
    },
    isValidDateView(state, getters) {
        let isValid = state.expositionPeriodGuid.length > 0;

        if (
            getters.hasUpsellTickets &&
            state.upsellExpositionPeriodGuid.length === 0
        ) {
            isValid = false;
        }

        return isValid;
    },
    isValidPaymentView(state, getters) {
        let isValid =
            state.email &&
            state.firstName &&
            state.lastName &&
            state.country &&
            state.agreeTermsAndConditions;
        if (getters.getSubtotal < 0 && !state.paymentOption.id) {
            isValid = false;
        }
        return isValid;
    }
};

export default getters;
