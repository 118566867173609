<template>
    <div class="subtotal">
        <div class="subtotal__lines">
            <div class="subtotal__tickets">
                <div v-for="ticket in selectedTickets" :key="ticket.id" class="subtotal__line">
                    <span class="subtotal__text-line"><b>{{ ticket.amount }}x</b> {{ ticket.name }} {{ ticket.help }}</span>
                    <div class="subtotal__price-container">
                        <span class="subtotal__old-price-line">{{ getTotalOriginalPrice(ticket) }}</span>
                        <span class="subtotal__price-line">{{ getTotalPrice(ticket) }}</span>
                    </div>
                </div>
            </div>
            <div class="subtotal__upsell-tickets">
                <div v-for="ticket in selectedUpsellTickets" :key="ticket.id" class="subtotal__line">
                    <span class="subtotal__text-line"><b>{{ ticket.amount }}x</b> {{ ticket.name }} {{ ticket.help }}</span>
                    <div class="subtotal__price-container">
                        <span class="subtotal__old-price-line">{{ getTotalOriginalPrice(ticket) }}</span>
                        <span class="subtotal__price-line">{{ getTotalPrice(ticket) }}</span>
                    </div>
                </div>
            </div>
            <div class="subtotal__articles">
                <div v-for="article in selectedArticles" :key="article.id" class="subtotal__line">
                    <span class="subtotal__text-line"><b>{{ article.amount }}x</b> {{ article.title }} {{ article.help_text }}</span>
                    <span class="subtotal__price-line">{{ getTotalPrice(article) }}</span>
                </div>
            </div>
            <div class="subtotal__articles">
                <div v-for="article in selectedAdditionalArticles($store.state.datetime.date)" :key="article.id" class="subtotal__line">
                    <span class="subtotal__text-line"><b>{{ article.amount }}x</b> {{ article.title }} {{ article.help_text }}</span>
                    <span class="subtotal__price-line">{{ getTotalPrice(article) }}</span>
                </div>
            </div>
        </div>
        <hr class="subtotal__divider">
        <template v-if="$store.state.order.donationPrice > 0">
            <div class="subtotal__lines">
                <div class="subtotal__articles">
                    <div class="subtotal__line">
                        <span class="subtotal__text-line">{{ $store.state.general.street.general_text.donation }}</span>
                        <span class="subtotal__price-line">{{ toCurrency($store.state.order.donationPrice, $store.state.general.language) }}</span>
                    </div>
                </div>
            </div>
            <hr class="subtotal__divider">
        </template>
        <template v-if="$store.state.order.discounts.length > 0">
            <div v-for="discount in $store.state.order.discounts" :key="discount.couponID" class="subtotal__lines">
                <div class="subtotal__articles">
                    <div class="subtotal__line">
                        <span class="subtotal__text-line">{{ discount.couponCode }}</span>
                        <span class="subtotal__price-line subtotal__price-line--colored">- {{ toCurrency(discount.discountAmount, $store.state.general.language) }}</span>
                    </div>
                </div>
            </div>
            <hr class="subtotal__divider">
        </template>
        <div class="subtotal__total">
            <div class="subtotal__text">{{ $store.state.general.street.general_text.subtotal }}</div>
            <div class="subtotal__price">{{ toCurrency($store.getters.getSubtotal, $store.state.general.language) }}</div>
        </div>

        <transition name="fade-in-out">
            <p v-if="next_message" class="subtotal__next-message" v-bind:class="{ 'subtotal__next-message--hidden' : $store.getters.isValidPaymentView }">{{ next_message }}</p>
        </transition>
        <LoadingButton :text="button_text" :disabled="button_disabled" @click=next_page />
    </div>
</template>

<script>
import "./subtotal.scss"

import { mapGetters } from 'vuex'

import LoadingButton from "../Button/LoadingButton"

export default {
    name: "Subtotal",
    props: {
        next_page: Function,
        next_message: String,
        button_text: String,
        button_disabled: Boolean,
    },
    components: {
        LoadingButton
    },
    computed: {
        ...mapGetters([
            'selectedTickets',
            'selectedUpsellTickets',
            'selectedArticles',
            'selectedAdditionalArticles',
        ]),
    },
    methods: {
        getTotalOriginalPrice(ticket) {
            if (ticket.price != ticket.original_price) {
                return this.toCurrency((ticket.amount * ticket.original_price), this.$store.state.general.language);
            } else {
                return null;
            }
        },
        getTotalPrice(ticket) {
            return this.toCurrency((ticket.amount * ticket.price), this.$store.state.general.language);
        }
    }
}
</script>
