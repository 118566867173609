//! From: https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart/store
import {format, parse} from "date-fns";
import {enGB, nl} from "date-fns/locale";

import fastlane from "../../api/fastlane";

// initial state
// shape: [{ id, quantity }]
const state = () => ({
    date: null,
    time: null,
    upsell_time: null,
    sold_out: [],
    timeslots: [],
    upsellTimeslots: []
});

// getters
const getters = {
    getSoldout: state => {
        return state.sold_out;
    },
    isSoldout: state => soldoutDate => {
        return state.sold_out.includes(soldoutDate);
    },
    getTime: (state, _getters, rootState) => (upsell, has_placeholder) => {
        if (upsell) {
            if (state.upsell_time) {
                return state.upsell_time;
            }
            if (has_placeholder) {
                return "";
            }
            return rootState.general.street.general_text.select_your_time;
        } else {
            if (state.time) {
                return state.time;
            }
            if (has_placeholder) {
                return "";
            }
            return rootState.general.street.general_text.select_your_time;
        }
    },
    getTimePlaceholder: (state, _getters, rootState) => upsell => {
        if (upsell) {
            return rootState.general.street.general_text.select_your_time;
        } else {
            return rootState.general.street.general_text.select_your_time;
        }
    },
    getDate(state, _getters, rootState) {
        if (state.date) {
            let locale = nl;
            if (rootState.general.language === "en") {
                locale = enGB;
            }
            const date = parse(state.date, "yyyy-MM-dd", new Date());
            return format(date, "d MMMM yyyy", { locale: locale });
        }
        return rootState.general.street.general_text.select_your_date;
    },
    getGTMDateTime(_state) {
        const inputDate = new Date(`${_state.date}T${_state.time}`);

        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth()+1).toString().padStart(2, "0");
        const day = inputDate.getDate().toString().padStart(2, "0");
        const hour = inputDate.getHours().toString().padStart(2, "0");
        const minute = inputDate.getMinutes().toString()
            .padStart(2, "0");

        return `${year}-${month}-${day} | ${hour}:${minute}`;
    }
};

// mutations
const mutations = {
    setTime(state, text) {
        state.time = text;
    },
    setUpsellTime(state, text) {
        state.upsell_time = text;
    },
    setDate(state, date) {
        state.date = date;
    },
    addSoldOut(state, dates) {
        state.sold_out = state.sold_out.concat(dates);
    },
    resetSoldOut(state) {
        state.sold_out = [];
    },
    setTimeslots(state, dates) {
        state.timeslots = dates;
    },
    resetTimeslots(state) {
        state.timeslots = [];
    },
    setUpsellTimeslots(state, dates) {
        state.upsellTimeslots = dates;
    },
    resetUpsellTimeslots(state) {
        state.upsellTimeslots = [];
    },
    resetDateTime(state) {
        state.date = null;
        state.time = null;
        state.upsell_time = null;
        state.sold_out = [];
        state.timeslots = [];
        state.upsellTimeslots = [];
    }
};

const actions = {
    async getSoldOutDates(
        { commit, state, rootState },
        { instance, from, expositionIds }
    ) {
        let soldOut = state.sold_out;
        const known = state.sold_out.includes(from);

        if (!known) {
            const data = await fastlane.getSoldOutDates(
                rootState.general.language,
                expositionIds,
                from
            );
            soldOut = state.sold_out.concat(data);
            if (data) {
                commit("addSoldOut", data);
            }
        }
        if (instance) {
            instance.set("disable", soldOut);
        }
        return new Promise(resolve => {
            resolve(soldOut);
        });
    },
    async getTimePeriods({ commit, rootState }, { from, expositions }) {
        if (from) {
            const data = await fastlane.getTimeSlots(
                rootState.general.language,
                expositions,
                from
            );
            commit("setTimeslots", data);
        }
    },
    async getUpsellTimePeriods({ commit, rootState }, { from, expositions }) {
        if (from) {
            const data = await fastlane.getTimeSlots(
                rootState.general.language,
                expositions,
                from
            );
            commit("setUpsellTimeslots", data);
        }
    }
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions
};
