<template>
    <layout-default>
        <div class="venue">
            <Back />
            <Breadcrumbs :mobile=false />
            <Intro :title="$store.getters.selectedOption.title" :help_text="$store.getters.selectedOption.description" />
            <Blocks :venues="$store.getters.selectedOption.venues" />
            <More :text="$store.getters.selectedOption.more_info.more" />
        </div>
    </layout-default>
    <Modal id="modal-venue" v-if="$store.getters.selectedOption.more_info.title" :title="$store.getters.selectedOption.more_info.title" :description="$store.getters.selectedOption.more_info.description" />
</template>

<script>
import store from "../store";

import LayoutDefault from '@/layouts/LayoutDefault.vue';
import Intro from '@/components/Intro/Intro'
import Blocks from '@/components/Blocks/Blocks'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import More from '@/components/More/More'
import Back from '@/components/Back/Back'
import Modal from "@/components/Modal/Modal"

export default {
    name: "Venue",
    beforeRouteEnter(to, from, next) {
        const optionSlug = to.params.optionSlug
        const option = store.state.general.street.options.find(o => o.slug && o.slug === optionSlug)
        const optionGuid = option?.guid || next({name: "Home"});
        store.commit("setOption", optionGuid);
        next();
    },
    async beforeCreate() {
        await this.imageLoader(this.$store, this.$store.getters.selectedOption.background_image);
        this.$store.commit("setBackgroundImageHideOnMobile", true);
        this.$store.commit("setBackgroundImageSmall", false);
        this.$store.commit("showFooter", true);
        this.$store.commit("showBreadcrumbs", true);
        this.$store.commit("setBreadcrumbPage", "venue");
    },
    beforeUnmount() {
        this.$store.commit("setMainAnimations", false);
    },
    components: {
        LayoutDefault,
        Intro,
        Blocks,
        More,
        Breadcrumbs,
        Back,
        Modal,
    },
}
</script>
