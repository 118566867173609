/**
 * Removes HTML tags from a string.
 * @param {string} html
 * @return {string}
 */
export const stripHTML = html => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent;
};
