<template>
    <div :class="classes">
        <Ticket v-for="ticket in tickets"
            :key="ticket.id"
            :name="ticket.name"
            :help="ticket.help"
            :price="ticket.price"
            :base_price="ticket.base_price"
            :id="ticket.id"
            :amount="ticket.amount"
            :old_price="ticket.old_price"
            :description="ticket.description"
            :upsell="upsell"
            :park_name="park_name"
        />
    </div>
</template>

<script>
import "./ticket-group.scss"

import { mapGetters } from 'vuex'

import Ticket from "./Ticket"

export default {
    name: "TicketGroup",
    props: {
        upsell: Boolean,
        park_name: String,
    },
    components: {
        Ticket
    },
    computed: {
        ...mapGetters({
            selectedVenue: 'selectedVenue',
        }),
        classes() {
            let classes = "ticket-group";
            if (this.$store.state.order.expositionTickets.length < 3) {
                classes += " ticket-group--big-spacing";
            } else if (this.selectedVenue.upsales.length === 0 && this.$store.state.order.articles.length === 0) {
                classes += " ticket-group--big-spacing";
            }
            return classes;
        },
        tickets() {
            if (this.upsell) {
                return this.$store.state.order.upsellExpositionTickets;
            } else {
                return this.$store.state.order.expositionTickets;
            }
        }
    }
}
</script>
