<template>
    <div class="coupon">
        <div class="coupon__text">{{ $store.state.general.street.general_text.coupon_code }}</div>
        <div class="coupon__input-list">
            <div class="coupon__container" v-for="coupon in $store.state.order.couponCodes" :key="coupon">
                <div class="coupon__input coupon__input--selected">{{ coupon }}</div>
                <img class="coupon__input-delete" src="../../assets/close.svg" alt="close" @click="removeCoupon">
            </div>
            <input class="coupon__input" type="text" name="coupon" @keydown.enter="checkValid">
        </div>
        <button class="coupon__button" type="submit" @click="checkValid">{{ $store.state.general.street.general_text.apply_coupon }}</button>
    </div>
</template>

<script>
import "./coupon.scss"

import fastlane from '../../api/fastlane';

export default {
    name: "Coupon",
    methods: {
        removeCoupon(event) {
            const input = event.currentTarget.parentElement.querySelector(".coupon__input");
            this.$store.commit("setCouponCodes", this.$store.state.order.couponCodes.filter(code => code != input.innerText));
            if(this.$store.state.order.couponCodes.length > 0){
                this.handleCoupons(null);
            } else {
                this.$store.commit("setDiscounts", []);
            }
        },
        checkValid(event) {
            event.preventDefault();
            this.$parent.errors = [];
            const lastInput = [...document.querySelectorAll(".coupon__input")].pop();
            lastInput.setCustomValidity("");

            if (this.$store.state.order.couponCodes.includes(lastInput.value)) {
                lastInput.setCustomValidity(this.$store.state.general.street.general_text.coupon_exist_error);
                this.$parent.errors.push(this.$store.state.general.street.general_text.coupon_exist_error);
            } else if(lastInput.value) {
                this.handleCoupons(lastInput.value);
            }
        },
        async handleCoupons(coupon) {
            const {discounts, error} = await fastlane.couponCheck(this.$store.state.general.language, this.$store, coupon);

            const lastInput = [...document.querySelectorAll(".coupon__input")].pop();
            if (error) {
                lastInput.setCustomValidity(error.data);
                error.data.forEach(error_text => {
                    this.$parent.errors.push(error_text);
                });
            } else {
                if (discounts) {
                    this.$store.commit("setDiscounts", discounts);
                }
                if(coupon) {
                    this.$store.commit("setCouponCodes", [lastInput.value].concat(this.$store.state.order.couponCodes));
                }
                lastInput.value = ""
            }
        }
    }
}
</script>
