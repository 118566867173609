<template>
    <div class="payment-options-blocks">
        <span class="payment-errors" id="payment_option"></span>
        <a
            v-for="paymentOption in paymentOptions"
            :key="paymentOption"
            class="payment-options"
            v-bind:class="{
                'payment-options--selected':
                    $store.state.order.paymentOption.id === paymentOption.id
            }"
            @click="
                select(
                    paymentOption,
                    paymentOption.adyenissuer_set.length > 0,
                    $event
                )
            "
        >
            <span class="payment-options__text-light">
                <span
                    :id="'payment' + paymentOption.id"
                    :data-default="paymentOption.name"
                    >{{ paymentOption.name }}</span
                >
            </span>
            <img
                class="payment-options__image"
                :src="paymentOption.image"
                :alt="paymentOption.name"
            />
            <div
                v-if="paymentOption.adyenissuer_set.length > 0"
                class="payment-dropdown"
                id="issuers"
            >
                <ul class="payment-dropdown__list">
                    <li
                        v-for="issuer in paymentOption.adyenissuer_set"
                        :key="issuer.id"
                        :data-name="issuer.name"
                        class="payment-dropdown__item"
                        @click="selectIssuer(issuer, paymentOption, $event)"
                    >
                        {{ issuer.name }}
                    </li>
                </ul>
            </div>
        </a>
    </div>
</template>

<script>
import "../Blocks/blocks.scss";
import "./payment-options.scss";

export default {
    name: "PaymentOptions",
    props: {
        paymentOptions: Array,
        fetch: Function
    },
    mounted() {
        this.fetch();
    },
    methods: {
        select: function(paymentOption, hasIssuers, event) {
            event.currentTarget.parentElement.classList.remove("checkbox--error")
            if (hasIssuers) {
                this.openDropdown(event);
            } else {
                this.setPaymentOption(paymentOption);
            }
        },
        setPaymentOption: function(paymentOption) {
            this.$store.commit("setPaymentOption", paymentOption);
            this.$store.commit("setIssuer", null);
            this.paymentOptions.forEach(option => {
                const paymentName = document.querySelector(
                    `#payment${option.id}`
                );
                paymentName.innerText = paymentName.dataset.default;
            });
        },
        openDropdown: function(event) {
            event.currentTarget.classList.toggle("payment-options--open");
        },
        selectIssuer: function(issuer, paymentOption, event) {
            event.stopPropagation();
            this.$store.commit("setIssuer", issuer);
            this.$store.commit("setPaymentOption", paymentOption);
            const paymentName = document.querySelector(
                `#payment${paymentOption.id}`
            );
            paymentName.innerText = event.currentTarget.dataset.name;
            event.currentTarget.parentElement.parentElement.parentElement.classList.remove(
                "payment-options--open"
            );
        }
    }
};
</script>
