import { createStore, createLogger } from 'vuex'
// import createPersistedState from "vuex-persistedstate";

// Modules
import general from './modules/general'
import datetime from './modules/datetime'
import order from './modules/order/index'
import tickets from './modules/tickets'

// // Vuex storage options
// const options = {
//     key: "artis-fastlane",
// }

const store = createStore({
    modules: {
        general,
        datetime,
        tickets,
        order,
    },
    strict: process.env.NODE_ENV !== 'production',
    plugins: process.env.LOG_LEVEL === 'DEBUG' ? [createLogger()] : [],
})

export default store
