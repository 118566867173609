<template>
    <a class="feature" @click="go_to_venue(id, text)">
        <div class="feature__image-container" :style="style">
            <img class="feature__image" :src="icon" alt="">
        </div>
        <div class="feature__texts">
            <div class="feature__text" v-html="text"></div>
        </div>
    </a>
</template>

<script>
import "./feature.scss"
import {useGtm} from "@gtm-support/vue-gtm";
import {stripHTML} from "@/lib/format/stripHTML";

export default {
    name: 'Feature',
    props: {
        id: String,
        slug: String,
        text: String,
        icon: String,
        color: String,
        feature: Object
    },
    computed: {
        style () {
            return 'background-color: ' + this.color;
        }
    },
    methods: {
        go_to_venue: async function (guid, text) {
            this.$store.commit("setOption", guid);
            await this.$router.push({ name: 'VenueWithGuid', params: { optionSlug: this.slug } })

            const gtm = useGtm();

            if(!gtm) {
                return;
            }

            gtm.trackEvent({
                event: "checkout",
                checkout_funnel_name: this.$store.getters.getGTMCheckoutFunnelName,
                checkout_stage_number: "1",
                checkout_stage_name: "checkout_start",
                checkout_option: stripHTML(text).toLowerCase()
            });
        }
    }
}
</script>
