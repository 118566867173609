const state = () => ({
    upsell: false,
})

// mutations
const mutations = {
    setUpsell(state, value) {
        state.upsell = value
    },
}

const getters = {
    getUpsell: state => state.upsell
};

export default {
    namespaced: false,
    state,
    getters,
    mutations
}
