<template>
    <div class="input-group">
        <div class="input" v-bind:class="{ 'input--filled' : $store.state.order.firstName }" :style="invalidText">
            <input id="first_name" type="text" :value=$store.state.order.firstName class="input__input" required="required" @keyup="setFirstName" autocomplete="off">
            <label class="input__label">{{ $store.state.general.street.general_text.first_name }}</label>
        </div>
        <div class="input" v-bind:class="{ 'input--filled' : $store.state.order.lastName }" :style="invalidText">
            <input id="last_name" type="text" :value=$store.state.order.lastName class="input__input" required="required" @keyup="setLastName" autocomplete="off">
            <label class="input__label">{{ $store.state.general.street.general_text.last_name }}</label>
        </div>
        <div class="input" v-bind:class="{ 'input--filled' : $store.state.order.email }" :style="invalidText">
            <input id="email" type="email" :value=$store.state.order.email class="input__input" required="required" @keyup="setEmail" autocomplete="off">
            <label class="input__label">{{ $store.state.general.street.general_text.email }}</label>
        </div>
        <Countries />
    </div>
</template>

<script>
import "./input-group.scss"
import './input.scss'

import Countries from '@/components/Select/Countries'

export default {
    name: "InputGroup",
    props: {
        inputs: Array,
    },
    components: {
        Countries,
    },
    methods: {
        setFirstName: function (event) {
            this.$store.commit("setFirstName", event.currentTarget.value);
        },
        setLastName: function (event) {
            this.$store.commit("setLastName", event.currentTarget.value);
        },
        setEmail: function (event) {
            this.$store.commit("setEmail", event.currentTarget.value);
        },
        setCountry: function (event) {
            this.$store.commit("setCountry", event.currentTarget.value);
        },
    },
    computed: {
        invalidText() {
            let invalidText = this.$store.state.general.street.general_text.email_error;
            const hasWhiteSpace = invalidText.endsWith(' ');

            if (!hasWhiteSpace){
                invalidText = `${invalidText} `;
            }

            return {
                '--invalidText': JSON.stringify(invalidText)
            };
        },
    },
}
</script>
