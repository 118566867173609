<template>
    <div class="errors">
        <div v-for="error in errors" :key="error" class="errors__error">
            <span>{{ error }}</span>
            <img class="errors__image" src="../../assets/close.svg" alt="close" @click="closeError">
        </div>
    </div>
</template>

<script>
import "./errors.scss"

export default {
    name: "Errors",
    props: {
        errors: Array,
    },
    methods: {
        closeError: (event) => {
            event.currentTarget.parentElement.remove();
        }
    }
}
</script>
