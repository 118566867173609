const state = () => ({
    // Tickets
    expositionTickets: [],
    expositionPeriodGuid: [],
    upsellExpositionTickets: [],
    upsellExpositionPeriodGuid: [],
    articles: [],
    additionalArticles: [],

    // Payment options
    paymentOption: {},
    issuer: null,

    // Donation
    discounts: [],
    couponCodes: [],
    donation: {},
    donationPrice: 0,

    // Personal information
    email: "",
    firstName: "",
    lastName: "",
    country: {},
    artisNewsletter: false,
    micropiaNewsletter: false,
    grootemuseumNewsletter: false,

    // Terms and conditions
    agreeTermsAndConditions: false
});

export default state;
