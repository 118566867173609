<template>
    <div class="menu">
        <svg class="menu__burger" v-on:click="open_menu()" viewBox="0 0 17 9" stroke="#000" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Open menu">
            <path d="M1 1H16" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 8H16" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="menu__options" aria-expanded="false">
            <div class="menu__header">
                <span class="menu__header-text">{{ $store.state.general.street.general_text.menu_go_to }}</span>
                <img class="menu__header-image" src="../../assets/close.svg" alt="close" @click="close_menu()" aria-label="Close menu">
            </div>
            <div class="menu__options-container">
                <a class="menu__option" href="https://www.artis.nl/" target="_blank" @click="close_menu()">
                    <img class="menu__option-image" src="../../assets/Artis-logo.png" alt="Artis">
                    <svg class="menu__icon" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.72701 1.5L8.27246 8L1.72701 14.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
                <a class="menu__option" href="https://www.micropia.nl/" target="_blank" @click="close_menu()">
                    <img class="menu__option-image" src="../../assets/micropia.png" alt="Micropia">
                    <svg class="menu__icon" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.72701 1.5L8.27246 8L1.72701 14.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
                <a class="menu__option" href="https://www.grootemuseum.nl/" target="_blank" @click="close_menu()">
                    <img class="menu__option-image" src="../../assets/grootemuseum.jpg" alt="Groote Museum">
                    <svg class="menu__icon" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.72701 1.5L8.27246 8L1.72701 14.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import "./menu.scss"

export default {
    name: "Menu",
    methods: {
        open_menu: function() {
            const options = document.querySelector(".menu__options");
            options.setAttribute('aria-expanded', 'true')
            document.addEventListener('keyup', this.close_menu)
        },
        close_menu: function(e) {
            if(!e || e.key === "Escape") {
                const options = document.querySelector(".menu__options");
                options.setAttribute('aria-expanded', 'false')
                document.removeEventListener('keyup', this.close_menu)
            }
        }
    }

}
</script>
