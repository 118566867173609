<template>
    <button :class="classes" >{{ text }}</button>
</template>

<script>
import "./button.scss"

export default {
    name: "Button",
    props: {
        text: String,
        block: Boolean,
        colored: Boolean,
        disabled: Boolean,
    },
    computed: {
        classes() {
            let classes = "button";
            if(this.block) {
                classes += " button--block";
            }
            if(this.colored) {
                classes += " button--colored";
            }

            return classes;
        },
        attributes() {
            return 'disabled="disabled"'
        }
    }
}
</script>
