<template>
    <div
        class="select"
        tabindex="0"
        @keydown.stop.prevent.down="keyDown($event)"
        @keydown.stop.prevent.up="keyUp($event)"
        @keydown.stop.prevent.enter="keySelect($event)"
        @focusout="clear($event)"
    >
        <div class="select__selected" v-bind:class="{ 'select__selected--default' : !hasTimePeriod(upsell) }">
            <input class="select__hidden" type="hidden" value="" />
            <span
                class="select__display"
                @click="toggle_dropdown($event)"
            >{{ time(upsell, false) }}</span>
            <div class="select___display-icon">
                <svg v-if="hasTimePeriod(upsell)" class="select__icon" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.56604 1.44653L3.45896 6.55334L1 3.71635" stroke="black" stroke-width="1.51321" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg v-if="!hasTimePeriod(upsell)" class="select__icon" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M 9.3408895,0.67699108 5.0034548,5.0447557 0.66601858,0.67699108" stroke="#000000" stroke-width="1.3346" stroke-linecap="round" stroke-linejoin="round" id="path2" />
                </svg>
            </div>
        </div>
        <div class="select__dropdown">
            <div
                v-for="option in options"
                :key="option.id"
                class="select__item"
                :data-option="JSON.stringify(option)"
                v-bind:class="{ 'select__item--selected' : option.data === selected }"
                @click="close_dropdown(option, $event)"
            >
                <span>{{ option.text }}</span>
                <div class="select__discount">
                    <span class="select__comment">{{ option.comment }}</span>
                </div>
                <div class="select__available" :availability="getAvailability(option)"></div>
            </div>
        </div>
    </div>
</template>

<script>
import "./select.scss"

import { mapGetters } from 'vuex'

export default {
    name: "Select",
    props: {
        placeholder: String,
        upsell: Boolean,
        options: Array,
    },
    created() {
        this.timeUnwatch = this.$store.watch(
            (state) => state.order.expositionPeriodGuid,
            (newPeriod) => {
                if (newPeriod.length > 0) {
                    this.$store.dispatch('getCurrentPrices');
                } else {
                    this.$store.commit("setOldPrice");
                }
            },
        );
        this.upsellTimeUnwatch = this.$store.watch(
            (state) => state.order.upsellExpositionPeriodGuid,
            (newPeriod) => {
                if (newPeriod.length > 0) {
                    this.$store.dispatch('getCurrentUpsellPrices');
                } else {
                    this.$store.commit("setOldUpsellPrice");
                }
            },
        );
    },
    beforeUnmount() {
        this.timeUnwatch();
        this.upsellTimeUnwatch();
    },
    computed: {
        ...mapGetters({
            time: 'getTime',
            timePlaceholder: 'getTimePlaceholder',
            tickets: "selectedTickets",
            hasTimePeriod: "hasTimePeriod",
        }),
        selected: function() {
            if(this.upsell) {
                return this.$store.state.order.upsellExpositionPeriodGuid;
            } else {
                return this.$store.state.order.expositionPeriodGuid;
            }
        }
    },
    methods: {
        getAvailability(option) {
            const lowAvailabilityOptions = option.data.filter(period => {
                return ((period.availability / period.maxAvailability) * 100) < 10
            });

            return lowAvailabilityOptions.length > 0 ? 'low': 'good';
        },
        toggle_dropdown(event) {
            event.preventDefault();
            event.stopPropagation();

            let select = event.currentTarget.parentElement.parentElement.querySelector(".select__selected");
            select.classList.toggle("select__selected--open");

            let dropdown = event.currentTarget.parentElement.parentElement.querySelector('.select__dropdown');
            dropdown.classList.toggle("select__dropdown--open");
        },
        keySelect(event) {
            let currentHighlight = event.currentTarget.querySelector('.select__item--highlight');
            if (currentHighlight) {
                let option = JSON.parse(currentHighlight.dataset.option);
                this.close_dropdown(option, event);
            }
        },
        keyUp(event) {
            let currentHighlight = event.currentTarget.querySelector('.select__item--highlight');
            let currentSelected = event.currentTarget.querySelector('.select__item--selected');
            if (currentHighlight) {
                let previousHighlight = currentHighlight.previousSibling;
                if(previousHighlight.nodeType === 1) {
                    previousHighlight.classList.add("select__item--highlight");
                    previousHighlight.scrollIntoView({behavior: "smooth", block:  'nearest'});
                    currentHighlight.classList.remove("select__item--highlight");
                }
            } else if (currentSelected) {
                let previousHighlight = currentSelected.previousSibling;
                if(previousHighlight.nodeType === 1) {
                    previousHighlight.classList.add("select__item--highlight");
                    previousHighlight.scrollIntoView({behavior: "smooth", block:  'nearest'});
                }
            }
        },
        keyDown(event) {
            let dropdown = event.currentTarget.querySelector('.select__dropdown');
            if (dropdown.classList.contains("select__dropdown--open")) {
                let currentHighlight = dropdown.querySelector('.select__item--highlight');
                let currentSelected = dropdown.querySelector('.select__item--selected');
                if (currentHighlight) {
                    let nextHighlight = currentHighlight.nextSibling;
                    if(nextHighlight.nodeType === 1) {
                        nextHighlight.classList.add("select__item--highlight");
                        nextHighlight.scrollIntoView({behavior: "smooth"});
                        currentHighlight.classList.remove("select__item--highlight");
                    }
                } else if (currentSelected) {
                    let nextHighlight = currentSelected.nextSibling;
                    if(nextHighlight.nodeType === 1) {
                        nextHighlight.classList.add("select__item--highlight");
                        nextHighlight.scrollIntoView({behavior: "smooth", block:  'nearest'});
                    }
                } else {
                    let firstItem = dropdown.querySelector('.select__item');
                    firstItem.classList.add("select__item--highlight");
                }
            } else {
                this.toggle_dropdown(event);
            }
        },
        close_dropdown(selected, event) {
            event.preventDefault();
            event.stopPropagation();

            let queryable = null;
            if (event.currentTarget.classList.contains("select")) {
                queryable = event.currentTarget;
            } else {
                queryable = event.currentTarget.parentElement.parentElement;
            }

            let select = queryable.querySelector(".select__selected");
            select.classList.remove("select__selected--open");
            select.querySelector(".select___display-icon").classList.add("select___display-icon--visible");

            if (this.upsell) {
                this.$store.commit("setUpsellTime", selected.text);
                this.$store.commit("setUpsellExpositionPeriodGuid", selected.data);
            } else {
                this.$store.commit("setTime", selected.text);
                this.$store.commit("setExpositionPeriodGuid", selected.data);
            }

            let dropdown = queryable.querySelector('.select__dropdown');
            dropdown.classList.remove("select__dropdown--open");
        },
        clear(event) {
            const currentTarget = event.currentTarget.parentElement;
            let dropdown = currentTarget.querySelector('.select__dropdown');
            dropdown.classList.remove("select__dropdown--open");
            let select = currentTarget.querySelector(".select__selected");
            select.classList.remove("select__selected--open");
        },
    }
}
</script>
