<template>
    <div class="home-intro">
        <div class="home-intro__container">
            <div :class="{ 'home-intro__title--animate' : $store.state.general.mainAnimations }" class="home-intro__title">{{ title }}</div>
            <div :class="{ 'home-intro__intro--animate' : $store.state.general.mainAnimations }" class="home-intro__intro" v-html="intro"></div>
        </div>
    </div>
</template>

<script>
import "./home_intro.scss"

export default {
    name: "HomeIntro",
    props: {
        title: String,
        intro: String,
        bold_intro: String
    }
}
</script>

// 156px
