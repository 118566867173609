//! From: https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart/store
import { isBefore, sub, add, parseISO } from 'date-fns'

import fastlane from '../../api/fastlane'
import {imageLoader} from '@/store/functions';

// initial state
// shape: [{ id, quantity }]
const state = () => ({
    isLoading: true,
    mainAnimations: true,
    expiryDate: sub(new Date(), { minutes: 60 }),
    background_image: null,
    background_change: false,
    background_zoom: false,
    background_image_hide_on_mobile: false,
    background_image_small: false,
    show_footer: false,
    show_breadcrumbs: false,
    breadcrumb_page: "",
    language: null,
    back_page: null,
    street: null,
    option: null,
    venue: null,
    show_scroll: false,
    countries: [],
    hasDate: true,
    showCoupons: false,
})

// getters
const getters = {
    isExpired: state => {
        const now = new Date();
        let expire = state.expiryDate;
        if (typeof (expire) === "string") {
            expire = parseISO(expire);
        }
        return isBefore(expire, now) && state.street !== null;
    },
    getBackgroundImage: (state) => {
        if (state.street) {
            return state.street.background_image;
        }
        return ""
    },
    getGTMCheckoutFunnelName: (state) => {
        const name = state.street?.slug || "regulier";
        return `ticketstraat_${name}`;
    },
    selectedOption: (state) => {
        const selectedOption = state.street.options.find(option => option.guid === state.option);
        if(selectedOption) {
            return selectedOption;
        }
        return {more_info: {}}
    },
    selectedVenue: (state) => {
        if (state.street) {
            const selectedOption = state.street.options.find(option => option.guid === state.option);
            if (selectedOption) {
                const selectedVenue = selectedOption.venues.find(venue => venue.guid === state.venue);
                if (selectedVenue) {
                    return selectedVenue;
                }
            }
        }

        return {
            park: {},
            upsales: [],
        }
    },
}

// mutations
const mutations = {
    setLoading: (state, value) => {
        state.isLoading = value;
    },
    setMainAnimations: (state, value) => {
        state.mainAnimations = value;
    },
    setExpiryDate: (state) => {
        state.expiryDate = add(new Date(), {minutes: 45});
    },
    setShowCoupon(state, value) {
        state.showCoupons = value;
    },
    setHasDate(state, value) {
        state.hasDate = value;
    },
    setBackgroundImage (state, value) {
        state.background_image = value;
    },
    setBackgroundChange (state, value) {
        if(value) {
            state.background_change = "main-image--animate";
        } else {
            state.background_change = null;
        }
    },
    setBackgroundImageHideOnMobile(state, value) {
        state.background_image_hide_on_mobile = value;
    },
    setBackgroundImageSmall(state, value) {
        state.background_image_small = value;
    },
    showFooter(state, value) {
        state.show_footer = value;
    },
    showBreadcrumbs(state, value) {
        state.show_breadcrumbs = value;
    },
    setBreadcrumbPage(state, value) {
        state.breadcrumb_page = value;
    },
    setLanguage(state, language) {
        state.language = language;
    },
    setBackPage(state, page_name) {
        state.back_page = page_name;
    },
    setFastlaneStreet(state, street) {
        state.street = street
    },
    setOption(state, guid) {
        state.option = guid;
    },
    setVenue(state, guid) {
        state.venue = guid;
    },
    setShowScroll(state, value) {
        state.show_scroll = value;
    },
    setCountries(state, countries) {
        state.countries = countries;
    },
    resetGeneral(state) {
        state.option = null;
        state.venue = null;
    },
    setBackgroundZoom(state, value) {
        state.background_zoom = value;
    }
}

const actions = {
    async getFastlaneStreet({ commit, state }) {
        commit("setLoading", true);
        const data = await fastlane.getStreet(state.language);
        await imageLoader(null, data.background_image)

        commit("setFastlaneStreet", data);
        commit("setExpiryDate");
        commit("updateExpositionTicketNames", data);
        commit("updateUpsellExpositionTicketNames", data);
        commit("updateArticleNames", data);
        commit("setLoading", false);
        return new Promise((resolve) => {
            resolve();
        })
    },
    async getCountries({commit, state}) {
        const data = await fastlane.getCountries(state.language)
        return commit("setCountries", data);
    },
    checkExpired({ commit }, reset) {
        return new Promise((resolve) => {
            if (reset) {
                commit("resetOrder");
                commit("resetDateTime");
                commit("setUpsell", false);
                commit("resetGeneral");
                commit("setFastlaneStreet", null);
                throw new Error("isExpired");
            } else {
                resolve();
            }
        })
    },
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
}
