<template>
  <div :class="classes" @click="chosen_action($event)">
    <svg v-if="icon === 'information'" class="more__image" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <circle cx="12" cy="12" r="12" fill="white"/>
        </g>
        <path d="M12.2356 11.6442V17.0624" stroke="black" stroke-width="1.54806" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.3496 8.16105C13.3496 8.7755 12.8508 9.27372 12.2355 9.27372C11.6201 9.27372 11.1214 8.7755 11.1214 8.16105C11.1214 7.54659 11.6201 7.04838 12.2355 7.04838C12.8508 7.04838 13.3496 7.54659 13.3496 8.16105Z" fill="black" stroke="black" stroke-width="0.096754"/>
    </svg>

    <svg v-if="icon === 'coupon'" class="more__image" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g><circle cx="12" cy="12" r="12" fill="white"/></g>
        <path d="M15.0625 7.625H17.25V9.8125" stroke="black" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.9375 16.375H6.75V14.1875" stroke="black" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.25 14.1875V16.375H15.0625" stroke="black" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.75 9.8125V7.625H8.9375" stroke="black" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.375 9.8125V14.1875" stroke="black" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.625 9.8125V14.1875" stroke="black" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.875 9.8125V14.1875" stroke="black" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.125 9.8125V14.1875" stroke="black" stroke-width="0.875" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'more'" class="more__image more__image--up" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g><circle cx="12" cy="12" r="12" fill="white"/></g>
        <path d="M11.9996 7V17" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="m 16.4996,11.5 -4.5,-4.5 -4.5,4.5" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'more'" class="more__image more__image--down" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g><circle cx="12" cy="12" r="12" fill="white"/></g>
        <path d="M11.9996 7V17" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.5 12.5L12 17L16.5 12.5" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <span class="more__text more__text--more">{{ text }}</span>
    <span v-if="icon === 'more'" class="more__text more__text--less">{{ less_text }}</span>
  </div>
</template>

<script>
import "./more.scss"

export default {
    name: 'More',
    props: {
        big: Boolean,
        text: String,
        less_text: String,
        modal_id: String,
        action: Function,
        icon: {
            type: String,
            default: 'information'
        },
    },
    components: {
    },
    computed: {
        classes() {
            let classes = "more"

            if (this.big) {
                classes += " more--big"
            }

            if (this.icon === "coupon") {
                classes += " more--coupon"
            }
            return classes
        },
    },
    methods: {
        chosen_action: function(event) {
            if (this.action) {
                this.action(event.currentTarget);
            } else {
                this.open_modal();
            }
        },
        open_modal: function () {
            const modal = (this.modal_id)
                ? document.querySelector(`.modal#${this.modal_id}`)
                : document.querySelector(".modal");

            if(modal) {
                modal.classList.add("modal--open");
            }
        }
    }
}
</script>
