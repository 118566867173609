<template>
    <div class="scroll-help" v-bind:class="{ 'scroll-help--hidden' : !$store.state.general.show_scroll}">
        <div class="scroll-help__gradient"></div>
        <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 0.923076L5.5 12" stroke="currentColor" stroke-width="1.38462" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 7.46853L5.5 12L10 7.46853" stroke="currentColor" stroke-width="1.38462" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="scroll-help__text">{{ $store.state.general.street.general_text.scroll_more  }}</div>
    </div>
</template>

<script>
import "./scroll-help.scss"

export default {
    name: "ScrollHelp",
}
</script>
