<template>
    <div :id="id" class="modal" data-simplebar>
        <div class="modal__container">
            <svg class="modal__close" @click="close_modal($event)" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.625 4.375L4.375 15.625" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.625 15.625L4.375 4.375" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="modal__title" v-html="title"></div>
            <div class="modal__text" v-html="computedDescription"></div>
        </div>
    </div>
</template>

<script>
import "./modal.scss"

export default {
    name: "Modal",
    props: {
        id: String,
        title: String,
        description: String,
    },
    mounted() {
        // Sync aria-hidden attribute opposite to modal--open class.
        // Implemented for compatibility for with legacy behaviour.
        const options = {attributes: true, childList: false, subtree: false};
        const observer = new MutationObserver(() => {
            const hidden = this.$el.matches(".modal--open") ? "false" : "true"
            observer.disconnect()
            this.$el.setAttribute("aria-hidden", hidden)
            observer.observe(this.$el, options)

            if (hidden === "false") {
                document.addEventListener('keyup', this.close_modal)
            }
        })
        observer.observe(this.$el, options)
    },
    computed: {
        computedDescription() {
            return this.description.replace(/\r\n/g, "<br>");
        }
    },
    methods: {
        close_modal: function (e) {
            if (!e.k || e.key === "Escape") {
                document.removeEventListener("keyup", this.close_modal)
                this.$el.classList.remove("modal--open");
            }
        }
    }
}
</script>
