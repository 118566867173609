export const getSplittedName = (splitableName) => {
    let splitted_name = splitableName.split("(");
    let name = splitted_name[0]
    let help = null;
    if (splitted_name[1]) {
        help = splitted_name[1].split(')')[0]
    }
    return {name: name, help: help}
}

export const addExpositionPrices = (exposition, upsell, store) => {
    let commitString = "addExpositionTicket"
    if(upsell) {
        commitString = "addUpsellExpositionTicket"
    }
    exposition.prices.forEach(price => {
        let {name, help} = getSplittedName(price.group_name);
        store.commit(commitString, {
            id: price.group_guid,
            name: name,
            help: help,
            upsell: upsell,
            price: Number(price.price),
            original_price: Number(price.price),
            old_price: null,
            amount: 0,
            base_price: null,
            exposition_id: exposition.guid,
            description: price.description,
        });
    });
}

export const addArticle = (article, store) => {
    let { name, help } = getSplittedName(article.title);

    let storeString = "addArticle";
    if (article.days_before > 0) {
        storeString = "addAdditionalArticle";
    }

    store.commit(storeString, {
        id: article.guid,
        title: name,
        help_text: help,
        price: Number(article.price),
        amount: 0,
        icon: "map",
        image: article.image,
        description: article.description,
        daysBefore: article.days_before,
    });
}

export const imageLoader = async (store, imageSrc) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            if(store) {
                if(store.state.general.background_image && store.state.general.background_image != imageSrc) {
                    store.commit("setBackgroundChange", true);
                }
                setTimeout(() => {
                    store.commit("setBackgroundImage", imageSrc);
                }, 300);
            }
            resolve(img);
        }
        img.onerror = reject;
        img.src = imageSrc;
    });
};

