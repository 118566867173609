<template>
    <div class="blocks">
        <a class="text-block">
            <span class="text-block__text-light">{{ $store.state.general.street.general_text.visiting_date }}</span>
            <span class="text-block__text">{{ date }}</span>
        </a>
        <a class="text-block">
            <span class="text-block__text-light">{{ $store.getters.selectedVenue.park.name }}</span>
            <span class="text-block__text">{{ time(false, false) }}</span>
        </a>
        <template v-if="hasUpsellTickets">
            <a class="text-block">
                <span class="text-block__text-light">{{ $store.getters.selectedVenue.upsell_park_name }}</span>
                <span class="text-block__text">{{ time(true, false) }}</span>
            </a>
        </template>
    </div>
</template>

<script>
import "./blocks.scss"
import "./text-block.scss"

import { mapGetters } from 'vuex'

export default {
    name: "TextBlocks",
    watch: {
        '$route' (currentRoute) {
            if(currentRoute.hash){
                const element = document.querySelector(currentRoute.hash);
                if(element) {
                    element.scrollIntoView({behavior: "smooth"});
                    document.dispatchEvent(this.scrollChangedEvent(element));
                    this.$router.push({ name: "Date", hash: null });
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            time: 'getTime',
            date: 'getDate',
            hasUpsellTickets: 'hasUpsellTickets',
        }),
    },
}
</script>
