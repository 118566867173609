<template>
    <div class="loading">
        <img class="loading__image" :src="backgroundImage">
        <div class="loading__text">
            <div v-if="text.length">
                {{ text }}
            </div>
        </div>
    </div>
</template>

<script>
import './loading.scss';

export default {
    name: 'loading',
    props: {
        active: Boolean,
    },
    data () {
        return {
            backgroundImage: document.body.dataset.bgimage,
            text: document.body.dataset.text,
        }
    },
}
</script>
