import fastlane from '../../../api/fastlane'

const findStateTickets = (blocked, tickets) => {
    return tickets.filter(compareTicket => {
        let compare = false;
        blocked.block_with.forEach(block_with => {
            if(compareTicket.id == block_with.group_guid) {
                compare = true
            }
        });
        return compare;
    });
}

const getHighestAmountBlockStateTicket = (compares) => {
    let compared = null;
    compares.forEach(compare => {
        if(!compared) {
            compared = compare;
        } else if(compare.amount > compared.amount) {
            compared = compare;
        }
    })
    return compared;
}

const canBeBought = (changedTicket, blockades, stateTickets) => {
    let canBuy = true;
    blockades.forEach((blocked) => {
        if(blocked.to_block.group_guid == changedTicket.id) {
            const blockStateTickets = findStateTickets(blocked, stateTickets);
            if(blockStateTickets) {
                const blockStateTicket = getHighestAmountBlockStateTicket(blockStateTickets);
                if(!blocked.amount_linked && blockStateTicket.amount < 1) {
                    canBuy = false;
                } else if(blocked.amount_linked && changedTicket.amount + 1 > blockStateTicket.amount) {
                    canBuy = false;
                }
            }
        }
    });
    return canBuy;
}

const inBlockWith = (blocked, ticket) => {
    let found = false;
    blocked.block_with.forEach(blockTicket => {
        if(blockTicket.group_guid === ticket.id){
            found = true;
        }
    })
    return found;
}

const checkLinked = (changedTicket, blockades, stateTickets) => {
    let lowered = [];
    let removed = [];
    let upsellLowered = [];
    let upsellRemoved = [];

    blockades.forEach((blocked) => {
        // Check if the changed ticket is in the block_with of blocked
        if(inBlockWith(blocked, changedTicket)) {
            // Get the local tickets that are in block_with for the amounts
            const blockStateTickets = findStateTickets(blocked, stateTickets);
            const blockStateTicket = getHighestAmountBlockStateTicket(blockStateTickets);

            // If the ticket is the same as the highest amount ticket make a change.
            // or if the highest amount ticket is set to 0
            if(changedTicket.id === blockStateTicket.id || blockStateTicket.amount === 0) {
                const toBlockStateTicket = stateTickets.find(toBlockTicket => toBlockTicket.id == blocked.to_block.group_guid);
                if(toBlockStateTicket) {
                    if(blocked.amount_linked) {
                        if(changedTicket.amount - 1 < toBlockStateTicket.amount) {
                            if(toBlockStateTicket.upsell) {
                                upsellLowered.push(toBlockStateTicket.id);
                            } else {
                                lowered.push(toBlockStateTicket.id);
                            }
                        }
                    } else if(changedTicket.amount - 1 <= 0) {
                        if(toBlockStateTicket.upsell) {
                            upsellRemoved.push(toBlockStateTicket.id);
                        } else {
                            removed.push(toBlockStateTicket.id);
                        }
                    }
                }
            }
        }
    });
    return {
        lowered: lowered,
        removed: removed,
        upsellLowered: upsellLowered,
        upsellRemoved: upsellRemoved,
    }
}

const checkTicketChange = (commit, stateTickets, blockades, ticket, add) => {
    if(!add) {
        const {lowered, removed, upsellLowered, upsellRemoved} = checkLinked(ticket, blockades, stateTickets);
        lowered.forEach(changed_id => {
            commit("changeExpositionTicketAmount", {ticket_id: changed_id, add: false});
        })
        removed.forEach(changed_id => {
            commit("resetExpositionTicketAmount", changed_id);
        })
        upsellLowered.forEach(changed_id => {
            commit("changeUpsellExpositionTicketAmount", {ticket_id: changed_id, add: false});
        })
        upsellRemoved.forEach(changed_id => {
            commit("resetUpsellExpositionTicketAmount", changed_id);
        })
    }
}

const actions = {
    changeExpositionTicketAmount ({ commit, state, rootState }, {ticket_id, add=true}) {
        const ticket = state.expositionTickets.find(ticket => ticket.id === ticket_id);
        const blockades = rootState.general.street.exposition_blockades;
        const allTickets = state.expositionTickets.concat(state.upsellExpositionTickets);
        checkTicketChange(commit, allTickets, blockades, ticket, add);
        if(canBeBought(ticket, blockades, allTickets) || !add) {
            commit("changeExpositionTicketAmount", {ticket_id: ticket_id, add: add});
            commit("setExpositionPeriodGuid", []);
            commit("setTime", null);
            commit("setCouponCodes", []);
            commit("setDiscounts", []);
        }
    },
    changeUpsellExpositionTicketAmount ({ commit, state, rootState }, {ticket_id, add=true}) {
        const ticket = state.upsellExpositionTickets.find(ticket => ticket.id === ticket_id);
        const blockades = rootState.general.street.exposition_blockades;
        const allTickets = state.expositionTickets.concat(state.upsellExpositionTickets);
        checkTicketChange(commit, allTickets, blockades, ticket, add);
        if(canBeBought(ticket, blockades, allTickets) || !add) {
            commit("changeUpsellExpositionTicketAmount", {ticket_id: ticket_id, add: add});
            commit("setUpsellExpositionPeriodGuid", []);
            commit("setUpsellTime", null);
        }
    },
    async getCurrentPrices ({ commit, state, rootState }) {
        for (const expositionPeriodGuid of state.expositionPeriodGuid) {
            const data = await fastlane.getCurrentPrices(rootState.general.language, expositionPeriodGuid.from, expositionPeriodGuid.expositionId)
            return commit("setCurrentPrices", data);
        }
    },
    async getCurrentUpsellPrices ({ commit, state, rootState }) {
        for (const upsellExpositionPeriodGuid of state.upsellExpositionPeriodGuid) {
            const data = await fastlane.getCurrentPrices(rootState.general.language, upsellExpositionPeriodGuid.from, upsellExpositionPeriodGuid.expositionId)
            return commit("setCurrentUpsellPrices", data);
        }
    }
}

export default actions
