<template>
    <div class="article-group" v-bind:class="{ 'article-group--spacing' : !selectedVenue.article_more_info }">
        <Intro v-if="showIntro" :title="selectedVenue.article_title" :dark=true />
        <Article v-for="article in articles"
            :key="article.id"
            :title="article.title"
            :help_text="article.help_text"
            :price="article.price"
            :icon="article.icon"
            :description="article.description"
            :image="article.image"
            :amount="article.amount"
            :id="article.id"
            :isAdditional="isAdditional"
            :park_name="park_name"
        />
        <More v-if="selectedVenue.article_more_info" :text="selectedVenue.article_more_info.more" :modal_title="selectedVenue.article_more_info.title" :modal_description="selectedVenue.article_more_info.description" />
    </div>
</template>

<script>
import "./article-group.scss"

import { mapGetters } from 'vuex'

import Article from "./Article"
import Intro from "../Intro/Intro"
import More from "../More/More"

export default {
    name: "ArticleGroup",
    props: {
        articles: Array,
        showIntro: {
            type: Boolean,
            default: true
        },
        isAdditional: {
            type: Boolean,
            default: false,
        },
        park_name: String
    },
    components: {
        Article,
        Intro,
        More,
    },
    computed: {
        ...mapGetters({
            selectedVenue: 'selectedVenue',
        })
    },
}
</script>
