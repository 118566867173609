<template>
    <div
        :class="classes"
        :style="getBackground"
    ></div>
</template>

<script>
import './main-image.scss';

export default {
    name: "MainImage",
    computed: {
        getBackground() {
            if (this.$store.state.general.background_image) {
                if(this.$store.state.general.background_image_hide_on_mobile && window.innerWidth < 768) {
                    return "";
                }
                return `background-image: url('${this.$store.state.general.background_image}');`;
            } else {
                return "";
            }
        },
        classes() {
            let allClasses = 'main-image';
            if(this.$store.state.general.background_change) {
                allClasses += " main-image--animate";
            }
            if(this.$store.state.general.background_image_small) {
                allClasses += ' main-image--smaller'
            }
            return allClasses;
        }
    },
}
</script>
