<template>
    <div :class="classes">
        <transition name="fade-in-out" mode="out-in">
            <div class="intro__container" :key="getTitle">
                <h1 class="intro__title" v-html="getTitle"></h1>
                <span v-if="help_text" class="intro__help-text">{{ help_text }}</span>
            </div>
        </transition>
    </div>
</template>

<script>
import "./intro.scss"

export default {
    name: "Intro",
    props: {
        title: String,
        park_name: String,
        help_text: String,
        date: Boolean,
        big_spacing: Boolean,
        dark: Boolean,
        light: Boolean,
    },
    computed: {
        getTitle() {
            let title = this.title;
            if (this.park_name) {
                title += ` ${this.park_name}?`;
            }
            // if (this.date) {
            //     title += ` ${this.$store.getters.getDate}`
            // }
            // if (this.park_name || this.date) {
            //     title += "?"
            // }
            return title;
        },
        classes() {
            let classes = "intro";
            if (this.big_spacing) {
                classes += " intro--spaced";
            }
            if (this.dark) {
                classes += " intro--dark";
            }
            if (this.light) {
                classes += " intro--light";
            }
            return classes;
        },
    }
}
</script>
