<template>
    <layout-default>
        <div class="home">
            <HomeIntro title="Page not found" intro="The requested page could not be found. Please try a different url." />
        </div>
    </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import HomeIntro from '@/components/Intro/HomeIntro'

export default {
    name: 'Home',
    beforeCreate() {
        this.$store.commit("setBackgroundImage", "https://www.artis.nl/media/filer_public_thumbnails/filer_public/62/a1/62a1e0cc-5076-4910-b87c-0423dcde6690/papegaaienlaandef.jpg__1920x0_q85_subject_location-1042%2C603_subsampling-2.jpg");
    },
    components: {
        LayoutDefault,
        HomeIntro,
    }
}
</script>
