<template>
    <layout-default>
        <div class="payment">
            <Errors :errors="errors" />
            <Back />
            <Breadcrumbs :mobile="false" />
            <Intro
                :title="$store.state.general.street.payment_view.donation_title"
                :big_spacing="true"
            />
            <Donations />
            <More
                v-if="
                    $store.state.general.street.payment_view.donation_more_info
                "
                :text="
                    $store.state.general.street.payment_view.donation_more_info
                        .more
                "
                modal_id="modal-payment-1"
            />
            <Intro
                :title="
                    $store.state.general.street.payment_view.personal_info_title
                "
                :dark="true"
            />
            <InputGroup />
            <CheckboxGroup />
            <More
                v-if="!showCoupons"
                icon="coupon"
                :text="
                    $store.state.general.street.payment_view.coupon_codes_text
                "
                :action="showCoupon"
            />
            <Coupon v-if="showCoupons" />
            <template v-if="showPaymentOptions">
                <Intro
                    :title="$store.state.general.street.payment_view.title"
                    :dark="true"
                />
                <PaymentOptions
                    :paymentOptions="paymentOptions"
                    :fetch="getPaymentOptions"
                />
            </template>
            <TextBlocks v-if="$store.state.general.hasDate" />
            <Subtotal
                :button_text="
                    $store.state.general.street.payment_view.next_button
                "
                :next_message="
                    $store.state.general.street.payment_view.next_message
                "
                :next_page="next_page"
                :button_disabled="false"
            />
        </div>
    </layout-default>
    <Modal
        id="modal-payment-1"
        v-if="$store.state.general.street.payment_view.donation_more_info.title"
        :title="
            $store.state.general.street.payment_view.donation_more_info.title
        "
        :description="
            $store.state.general.street.payment_view.donation_more_info
                .description
        "
    />
</template>

<script>
import fastlane from "../api/fastlane";
import store from "../store";

import LayoutDefault from "@/layouts/LayoutDefault.vue";
import Intro from "@/components/Intro/Intro";
import PaymentOptions from "@/components/Price/PaymentOptions";
import Donations from "@/components/Price/Donations";
import More from "@/components/More/More";
import InputGroup from "@/components/Form/InputGroup";
import CheckboxGroup from "@/components/Form/CheckboxGroup";
import Subtotal from "@/components/Price/Subtotal";
import TextBlocks from "@/components/Blocks/TextBlocks";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import Back from "@/components/Back/Back";
import Coupon from "@/components/Price/Coupon";
import Errors from "@/components/Errors/Errors";
import Modal from "@/components/Modal/Modal.vue";
import { useGtm } from "@gtm-support/vue-gtm";

export default {
    name: "Payment",
    components: {
        LayoutDefault,
        Intro,
        PaymentOptions,
        More,
        Donations,
        InputGroup,
        Subtotal,
        TextBlocks,
        CheckboxGroup,
        Breadcrumbs,
        Back,
        Coupon,
        Errors,
        Modal
    },
    beforeRouteEnter(to, from, next) {
        const optionSlug = to.params.optionSlug;
        const option = store.state.general.street.options.find(
            o => o.slug && o.slug === optionSlug
        );
        const optionGuid = option?.guid || next({ name: "Home" });
        store.commit("setOption", optionGuid);

        const venueSlug = to.params.venueSlug;
        const venue = option.venues.find(v => v.slug && v.slug === venueSlug);
        const venueGuid = venue?.guid || next({ name: "Home" });
        store.commit("setVenue", venueGuid);

        if (!store.getters.hasBoughtItems) {
            next({ name: "TicketsView" });
        } else {
            next();
        }
    },
    async beforeCreate() {
        await this.imageLoader(
            this.$store,
            this.$store.getters.selectedVenue.background_image
        );
        this.$store.commit("setBackgroundImageHideOnMobile", false);
        this.$store.commit("setBackgroundImageSmall", true);
        this.$store.commit("showFooter", true);
        this.$store.commit("showBreadcrumbs", true);
        this.$store.commit("setBreadcrumbPage", "payment");

        this.$store.dispatch("getCountries");
    },
    beforeUnmount() {
        this.$store.commit("setMainAnimations", false);
    },
    data() {
        return {
            paymentOptions: [],
            errors: []
        };
    },
    computed: {
        showPaymentOptions() {
            const subtotal = this.$store.getters.getSubtotal;
            if (subtotal > 0) {
                return true;
            } else {
                return false;
            }
        },
        showCoupons() {
            let showCoupon = this.$store.state.general.showCoupons;
            if (this.$store.state.order.couponCodes.length > 0) {
                showCoupon = true;
            }
            return showCoupon;
        }
    },
    methods: {
        showCoupon() {
            this.$store.commit("setShowCoupon", true);
        },
        async next_page(event) {
            let target = event.currentTarget;
            if (!target.classList.contains("active")) {
                this.errors = [];
                target.classList.add("active");
                let { data, error } = await fastlane.createOrder(
                    this.$store.state.general.language,
                    this.$store
                );
                if (error) {
                    if (typeof error.data === "object") {
                        for (const [field, value] of Object.entries(
                            error.data
                        )) {
                            let fieldElement;

                            try {
                                fieldElement = document.querySelector(
                                    `#${field}`
                                );
                            } catch (e) {
                                console.warn(error);
                            }

                            if (fieldElement) {
                                fieldElement.parentElement.style.setProperty(
                                    "--invalidText",
                                    `"${value} " `
                                );
                                fieldElement.parentElement.classList.add(
                                    "input--filled"
                                );
                                fieldElement.parentElement.classList.add(
                                    "checkbox--error"
                                );
                                fieldElement.classList.add(
                                    "input__input--invalid"
                                );
                                fieldElement.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                    inline: "nearest"
                                });
                                document.dispatchEvent(
                                    this.scrollChangedEvent(fieldElement)
                                );
                            } else {
                                if (isNaN(field)) {
                                    this.errors.push(`${field}: ${value}`);
                                } else {
                                    this.errors.push(value);
                                }
                            }
                        }
                        this.errors.push(
                            this.$store.state.general.street.general_text
                                .main_error
                        );
                    } else {
                        this.errors.push(
                            this.$store.state.general.street.general_text
                                .general_error
                        );
                        console.error(error.data);
                    }
                    target.classList.remove("active");
                } else {
                    await this.gtmTrackCheckout();
                    window.location = `/${this.$store.state.general.language}/orders/${data.reference}/payment/`;
                }
            }
        },
        async getPaymentOptions() {
            let data = await fastlane.getPaymentOptions(
                this.$store.state.general.language
            );
            this.paymentOptions = data;
        },
        async gtmTrackCheckout() {
            const couponCodes = this.$store.getters.getSelectedCouponCodes;
            const gtm = useGtm();

            if(!gtm) {
                return;
            }

            const items = this.$store.getters.getGTMItems;

            // Store `this.$store.getters.getGTMItems` in session storage.
            // We need to access the items for GTM events when the purchase is complete.
            // The value of `this.$store.getters.getGTMItems` however, may contain additional information not present on
            // the data model.
            // Caching the value improves consistency.
            sessionStorage.setItem('gtmItems', JSON.stringify(items));

            return new Promise(resolve => {
                gtm.trackEvent({
                    event: "checkout",
                    checkout_funnel_name: this.$store.getters
                        .getGTMCheckoutFunnelName,
                    checkout_stage_number: "5",
                    checkout_stage_name: "betalen",
                    payment_method:
                        this.$store.getters.getSelectedPaymentOption?.name ||
                        "",
                    coupon: couponCodes.join(" | ") || null,
                    discount: this.$store.getters.getDiscountPrice ? Math.abs(
                        this.$store.getters.getDiscountPrice
                    ).toFixed(2) : null,
                    discount_type: couponCodes.length ? "coupon" : null,
                    newsletter_optin: this.$store.getters
                        .getSelectedNewsletters,
                    value: this.$store.getters.getGTMItemValue.toFixed(2),
                    items: items,
                    eventCallback: resolve
                });
                setTimeout(resolve, 1000);
            });
        }
    }
};
</script>
