<template>
    <div
        tabindex="0"
        class="ticket"
        v-bind:class="{ 'ticket--selected': amount > 0 }"
        @keydown.stop.prevent.up="changeTicket(true)"
        @keydown.stop.prevent.down="changeTicket(false)"
    >
        <div class="ticket__info">
            <div class="ticket__main">
                <div class="ticket__location">
                    {{ name }}
                    <svg
                        v-if="description"
                        class="ticket__info-icon"
                        viewBox="0 0 120 120"
                        version="1.1"
                        @click="open_modal(id)"
                    >
                        <g id="layer1">
                            <path
                                d="m 60.000214,115.38458 c 30.588087,0 55.384396,-24.796396 55.384396,-55.384585 0,-30.588189 -24.796309,-55.3845751 -55.384396,-55.3845751 -30.58809,0 -55.3847941,24.7963861 -55.3847941,55.3845751 0,30.588189 24.7967041,55.384585 55.3847941,55.384585 z"
                                stroke="#000000"
                                stroke-width="9.23084"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                id="path2"
                                style="stroke-width:9.231;stroke-miterlimit:4;stroke-dasharray:none;fill:none"
                            />
                            <path
                                d="M 60.000214,55.384415 V 87.692287"
                                stroke="#000000"
                                stroke-width="9.23084"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                id="path4"
                            />
                            <path
                                d="m 66.63449,34.615498 c 0,3.664235 -2.970456,6.634698 -6.634276,6.634698 -3.664221,0 -6.634677,-2.970463 -6.634677,-6.634698 0,-3.664235 2.970456,-6.634697 6.634677,-6.634697 3.664224,0 6.634276,2.970462 6.634276,6.634697 z"
                                fill="#000000"
                                stroke="#000000"
                                stroke-width="0.576923"
                                id="path6"
                            />
                        </g>
                    </svg>
                    <Modal
                        :id="`modal-ticket-${id}`"
                        v-if="description"
                        :title="name"
                        :description="description"
                    />
                </div>
                <div class="ticket__age">{{ help }}</div>
            </div>
            <div class="ticket__prices">
                <div
                    class="ticket__price"
                    v-bind:class="{ 'ticket__price--discounted': old_price }"
                >
                    <span v-if="amount > 0" class="ticket__amount"
                        >{{ amount }}x</span
                    >
                    <span
                        v-if="amount === 0 && old_price"
                        class="ticket__old-price"
                        >{{
                            toCurrency(old_price, $store.state.general.language)
                        }}</span
                    >
                    {{ toCurrency(price, $store.state.general.language) }}
                </div>
                <div v-if="!old_price && price > 0" class="ticket__price-help">
                    {{ $store.state.general.street.general_text.base_price }}
                </div>
            </div>
        </div>
        <div class="ticket__buttons">
            <button
                class="ticket__button ticket__button--plus"
                tabindex="-1"
                v-on:click="changeTicket(true)"
            >
                <svg
                    class="ticket__add"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.42859 6.48975L11.6327 6.48975"
                        stroke="black"
                        stroke-width="1.40625"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M6.53064 1.3877L6.53064 11.5918"
                        stroke="black"
                        stroke-width="1.40625"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
            <button
                class="ticket__button ticket__button--minus"
                tabindex="-1"
                v-on:click="changeTicket(false)"
            >
                <svg
                    class="ticket__substract"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.42859 6.48975L11.6327 6.48975"
                        stroke="black"
                        stroke-width="1.40625"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import "./ticket.scss";

import Modal from "@/components/Modal/Modal";
import { useGtm } from "@gtm-support/vue-gtm";

export default {
    name: "Ticket",
    props: {
        name: String,
        help: String,
        description: String,
        price: Number,
        base_price: String,
        id: String,
        amount: Number,
        old_price: String,
        upsell: Boolean,
        park_name: String
    },
    components: {
        Modal
    },
    methods: {
        changeTicket: async function(add) {
            const tickets = (this.upsell)
                ? this.$store.state.order.upsellExpositionTickets
                : this.$store.state.order.expositionTickets;

            const ticket = tickets.find(t => t.id === this.id)
            const amount = ticket.amount
            if (this.upsell) {
                await this.$store.dispatch(
                    "changeUpsellExpositionTicketAmount",
                    { ticket_id: this.id, add: add }
                );
            } else {
                await this.$store.dispatch("changeExpositionTicketAmount", {
                    ticket_id: this.id,
                    add: add
                });
            }

            const gtm = useGtm();

            if(!gtm) {
                return;
            }

            const _price = this.price.toFixed(2);
            const newAmount = Math.max(
                add ? amount + 1 : amount - 1,
                0
            );

            // Don't track when nothing changed.
            if (newAmount === amount) {
                return;
            }

            const name = this.name ? String(this.name).trim() : '';
            const item_name = this.help ? `${name} | ${this.help}` : name;

            gtm.trackEvent({
                event: add ? "add_to_cart" : "remove_from_cart",
                value: _price,
                items: [
                    {
                        item_id: this.id,
                        item_name: item_name.toLowerCase(),
                        item_brand: this.park_name?.toLowerCase(),
                        item_category: this.upsell
                            ? "tickets_upsell"
                            : "tickets_regular",
                        item_category2: null,
                        price: _price,
                        quantity: "1",
                        discount: null,
                        coupon: null
                    }
                ]
            });
        },
        open_modal: function(id) {
            const modal = document.querySelector(`.modal#modal-ticket-${id}`);
            if (modal) {
                modal.classList.add("modal--open");
            }
        }
    }
};
</script>
