import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'

import { imageLoader } from '../store/functions'

import Home from '../views/Home.vue'
import Venue from '../views/Venue.vue'
import Tickets from '../views/Tickets.vue'
import DateView from '../views/Date.vue'
import Payment from '../views/Payment.vue'
import NotFound from '../views/NotFound.vue'
import {useGtm} from "@gtm-support/vue-gtm";

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
        depth: 0,
    },
}, {
    name: 'VenueWithGuid',
    path: '/:optionSlug',
    component: Venue,
    meta: {
        depth: 1,
    },
}, {
    name: 'TicketsViewWithSlug',
    path: '/:optionSlug/:venueSlug',
    component: Tickets,
    meta: {
        depth: 2,
    },
}, {
    name: 'Date',
    path: '/:optionSlug/:venueSlug/date',
    component: DateView,
    meta: {
        depth: 3,
    },
}, {
    name: 'Payment',
    path: '/:optionSlug/:venueSlug/payment',
    component: Payment,
    meta: {
        depth: 4,
    },
}, {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: NotFound
}]

const basePath = document.body.dataset.slug

const router = createRouter({
    history: createWebHistory(basePath),
    routes,
    scrollBehavior: function() {
        // if(to.hash || to.name === from.name) {
        //     return
        // }
        return { left: 0, top: 0 }
    },
})

router.beforeEach(async (to, from, next) => {
    const gtm = useGtm();
    if (gtm) {
        const isGTMEnabled = Boolean(window.CookieConsent?.consent?.statistics);
        // `VUE_APP_GTM_ID=GTM-XXXXXX` (.env) enables GTM for debug purposes.
        const isGTMInDebugMode = gtm.id === 'GTM-XXXXXX' || String(process.env.VUE_APP_GTM_DEBUG).toLowerCase() === "true";

        gtm.enable(isGTMEnabled || isGTMInDebugMode);
        gtm.debug(isGTMInDebugMode);
        sessionStorage.setItem(
            "isGTMEnabled",
            isGTMEnabled || isGTMInDebugMode
        );

        if(isGTMInDebugMode) {
            console.debug('GTM:', 'GTM ENABLED FOR DEBUGGING PURPOSES');
        }
    }

    try {
        await store.dispatch("checkExpired", store.getters.isExpired);
    } catch (error) {
        next({ name: 'Home' });
    }
    if (store.state.general.street === null) {
        await store.dispatch('getFastlaneStreet', store.getters.isExpired);
    }

    if (from.name) {
        const backgroundImageKey = ["VenueWithGuid", "TicketsViewWithSlug", "Date", "Payment"].includes(to.name)
          ? "selectedVenue.background_image"
          : "getBackgroundImage";

        const backgroundImage = store.getters[backgroundImageKey] || store.getters.getBackgroundImage;
        await imageLoader(store, backgroundImage);
    }
    next();
})

export default router
