<template>
    <div class="blocks">
        <a v-for="venue in venues" :key="venue.guid" class="block" v-on:click="go_to_tickets(venue.slug, venue.park.name)">
            <img class="block__image" :src="venue.park.image" alt="">
            <div class="block__texts block__texts--small" v-bind:class="{ 'block__texts--image' : venue.park.logo }">
                <img class="block__logo" :src="venue.park.logo" :alt="venue.park.name">
                <span class="block__text">{{ venue.park.name }}</span>
            </div>
        </a>
    </div>
</template>

<script>
import "./blocks.scss"
import "./block.scss"
import {useGtm} from "@gtm-support/vue-gtm";

export default {
    name: "Blocks",
    props: {
        venues: Array,
    },
    methods: {
        go_to_tickets: async function (venueSlug, text) {
            const optionSlug = this.$store.getters.selectedOption.slug;
            await this.$router.push({ name: 'TicketsViewWithSlug', params: { optionSlug, venueSlug} });

            const gtm = useGtm();

            if(!gtm) {
                return;
            }

            gtm.trackEvent({
               event: "checkout",
               checkout_funnel_name: this.$store.getters.getGTMCheckoutFunnelName,
               checkout_stage_number: "2",
               checkout_stage_name: "locatie",
               checkout_option: text?.toLowerCase(),
            });
        }
    }
}
</script>
