import { getSplittedName } from "../../functions";

const mutations = {
    // Exposition tickets
    addExpositionTicket(state, ticket) {
        state.expositionTickets.push(ticket);
    },
    resetExpositionTickets(state) {
        state.expositionTickets = [];
    },
    changeExpositionTicketAmount(state, { ticket_id, add = true }) {
        const ticket = state.expositionTickets.find(
            ticket => ticket.id === ticket_id
        );
        if (ticket && add) {
            ticket.amount += 1;
        } else if (ticket && ticket.amount > 0) {
            ticket.amount -= 1;
        }
    },
    resetExpositionTicketAmount(state, ticket_id) {
        const ticket = state.expositionTickets.find(
            ticket => ticket.id === ticket_id
        );
        ticket.amount = 0;
    },
    updateExpositionTicketNames(state, street) {
        let allExpositionTickets = [];
        street.options.forEach(option => {
            option.venues.forEach(venue => {
                if (venue.exposition) {
                    venue.exposition.prices.forEach(price => {
                        allExpositionTickets.push(price);
                    });
                }
            });
        });

        state.expositionTickets.forEach(ticket => {
            const newTicket = allExpositionTickets.find(
                tempTicket => tempTicket.group_guid == ticket.id
            );
            let { name, help } = getSplittedName(newTicket.group_name);
            ticket.name = name;
            ticket.help = help;
            ticket.description = newTicket.description;
        });
    },

    // Upsell exposition tickets
    addUpsellExpositionTicket(state, ticket) {
        state.upsellExpositionTickets.push(ticket);
    },
    resetUpsellExpositionTickets(state) {
        state.upsellExpositionTickets = [];
    },
    changeUpsellExpositionTicketAmount(state, { ticket_id, add = true }) {
        const ticket = state.upsellExpositionTickets.find(
            ticket => ticket.id === ticket_id
        );
        if (ticket && add) {
            ticket.amount += 1;
        } else if (ticket && ticket.amount > 0) {
            ticket.amount -= 1;
        }
    },
    resetUpsellExpositionTicketAmount(state, ticket_id) {
        const ticket = state.upsellExpositionTickets.find(
            ticket => ticket.id === ticket_id
        );
        ticket.amount = 0;
    },
    updateUpsellExpositionTicketNames(state, street) {
        let allUpsellExpositionTickets = [];
        street.options.forEach(option => {
            option.venues.forEach(venue => {
                if (venue.upsales) {
                    venue.upsales.forEach(upsell => {
                        upsell.prices.forEach(price => {
                            allUpsellExpositionTickets.push(price);
                        });
                    });
                }
            });
        });

        state.upsellExpositionTickets.forEach(ticket => {
            const newTicket = allUpsellExpositionTickets.find(
                tempTicket => tempTicket.group_guid == ticket.id
            );
            let { name, help } = getSplittedName(newTicket.group_name);
            ticket.name = name;
            ticket.help = help;
            ticket.description = newTicket.description;
        });
    },

    // Articles
    addArticle(state, article) {
        state.articles.push(article);
    },
    resetArticles(state) {
        state.articles = [];
    },
    changeArticleAmount(state, { article_id, add = true }) {
        const article = state.articles.find(
            article => article.id === article_id
        );
        if (article && add) {
            article.amount += 1;
        } else if (article && article.amount > 0) {
            article.amount -= 1;
        }
    },
    updateArticleNames(state, street) {
        let allArticles = [];
        street.options.forEach(option => {
            option.venues.forEach(venue => {
                if (venue.articles) {
                    venue.articles.forEach(article => {
                        allArticles.push(article);
                    });
                }
            });
        });

        state.articles.forEach(article => {
            const newArticle = allArticles.find(
                tempArticle => tempArticle.guid == article.id
            );
            let { name, help } = getSplittedName(newArticle.title);
            article.title = name;
            article.help_text = help;
            article.description = newArticle.description;
        });
    },

    // additionalArticles
    addAdditionalArticle(state, article) {
        state.additionalArticles.push(article);
    },
    resetAdditionalArticles(state) {
        state.additionalArticles = [];
    },
    changeAdditionalArticleAmount(state, { article_id, add = true }) {
        const article = state.additionalArticles.find(
            article => article.id === article_id
        );
        if (article && add) {
            article.amount += 1;
        } else if (article && article.amount > 0) {
            article.amount -= 1;
        }
    },
    updateAdditionalArticleNames(state, street) {
        let alladditionalArticles = [];
        street.options.forEach(option => {
            option.venues.forEach(venue => {
                if (venue.articles) {
                    venue.articles.forEach(article => {
                        alladditionalArticles.push(article);
                    });
                }
            });
        });

        state.additionalArticles.forEach(article => {
            const newArticle = alladditionalArticles.find(
                tempArticle => tempArticle.guid == article.id
            );
            let { name, help } = getSplittedName(newArticle.title);
            article.title = name;
            article.help_text = help;
            article.description = newArticle.description;
        });
    },

    // Period GUIDs
    setExpositionPeriodGuid(state, value) {
        state.expositionPeriodGuid = value;
    },
    setUpsellExpositionPeriodGuid(state, value) {
        state.upsellExpositionPeriodGuid = value;
    },

    // Prices
    setDiscounts(state, discounts) {
        state.discounts = discounts;
    },
    setCouponCodes(state, codes) {
        state.couponCodes = codes;
    },
    setDonation(state, donation) {
        state.donation = donation;
    },
    setDonationPrice(state, price) {
        state.donationPrice = price;
    },

    // Personal information
    setPaymentOption(state, guid) {
        state.paymentOption = guid;
    },
    setIssuer(state, guid) {
        state.issuer = guid;
    },
    setFirstName(state, value) {
        state.firstName = value;
    },
    setLastName(state, value) {
        state.lastName = value;
    },
    setEmail(state, value) {
        state.email = value;
    },
    setCountry(state, value) {
        state.country = value;
    },
    setNewsletterArtis(state, value) {
        state.artisNewsletter = value;
    },
    setNewsletterMicropia(state, value) {
        state.micropiaNewsletter = value;
    },
    setNewsletterGrooteMuseum(state, value) {
        state.grootemuseumNewsletter = value;
    },

    // Terms and conditions
    setTermsAndConditions(state, value) {
        state.agreeTermsAndConditions = value;
    },

    resetOrder(state) {
        state.expositionTickets = [];
        state.expositionPeriodGuid = [];
        state.upsellExpositionTickets = [];
        state.upsellExpositionPeriodGuid = null;
        state.articles = [];
        state.additionalArticles = [];
        state.paymentOption = {};
        state.issuer = null;
        state.discoutPrice = 0;
        state.donation = {};
        state.donationPrice = 0;
        state.email = "";
        state.firstName = "";
        state.lastName = "";
        state.country = {};
        state.artisNewsletter = false;
        state.micropiaNewsletter = false;
        state.grootemuseumNewsletter = false;
        state.agreeTermsAndConditions = false;
    },

    setCurrentPrices(state, prices) {
        prices.forEach(price => {
            const index = state.expositionTickets.findIndex(
                ticket => ticket.id === price.group.id
            );
            if (state.expositionTickets[index].price != price.amount) {
                state.expositionTickets[index].price = price.amount;
                if (
                    state.expositionTickets[index].original_price !=
                    price.amount
                ) {
                    state.expositionTickets[index].old_price =
                        state.expositionTickets[index].original_price;
                } else {
                    state.expositionTickets[index].old_price = null;
                }
            }
        });
    },
    setOldPrice(state) {
        state.expositionTickets.forEach(ticket => {
            if (ticket.old_price) {
                ticket.price = ticket.original_price;
                ticket.old_price = null;
            }
        });
    },
    setCurrentUpsellPrices(state, prices) {
        prices.forEach(price => {
            const index = state.upsellExpositionTickets.findIndex(
                ticket => ticket.id === price.group.id
            );
            if (state.upsellExpositionTickets[index].price != price.amount) {
                state.upsellExpositionTickets[index].price = price.amount;
                if (
                    state.upsellExpositionTickets[index].original_price !=
                    price.amount
                ) {
                    state.upsellExpositionTickets[index].old_price =
                        state.upsellExpositionTickets[index].original_price;
                } else {
                    state.upsellExpositionTickets[index].old_price = null;
                }
            }
        });
    },
    setOldUpsellPrice(state) {
        state.upsellExpositionTickets.forEach(ticket => {
            if (ticket.old_price) {
                ticket.price = ticket.original_price;
                ticket.old_price = null;
            }
        });
    }
};

export default mutations;
