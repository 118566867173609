<template>
    <div
        tabindex="0"
        :class="classes"
        @keydown.stop.prevent.up="changeArticle(true)"
        @keydown.stop.prevent.down="changeArticle(false)"
    >
        <div v-show="image" class="article__icon-container">
            <img class="article__icon" :src="image" :alt="icon" />
        </div>
        <div class="article__main">
            <div class="article__name">
                {{ title }}
                <svg
                    v-if="description"
                    class="article__info"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="open_modal(id)"
                >
                    <path
                        d="M6 11.25C8.8995 11.25 11.25 8.8995 11.25 6C11.25 3.10051 8.8995 0.75 6 0.75C3.10051 0.75 0.75 3.10051 0.75 6C0.75 8.8995 3.10051 11.25 6 11.25Z"
                        stroke="#474747"
                        stroke-width="0.875"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M6 5.5625V8.625"
                        stroke="#474747"
                        stroke-width="0.875"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M6.62891 3.59375C6.62891 3.94109 6.34734 4.22266 6 4.22266C5.65266 4.22266 5.37109 3.94109 5.37109 3.59375C5.37109 3.24641 5.65266 2.96484 6 2.96484C6.34734 2.96484 6.62891 3.24641 6.62891 3.59375Z"
                        fill="#474747"
                        stroke="#474747"
                        stroke-width="0.0546875"
                    />
                </svg>
                <Modal
                    :id="`modal-article-${id}`"
                    v-if="description"
                    :title="title"
                    :description="description"
                />
            </div>
            <div class="article__help-text">{{ help_text }}</div>
        </div>
        <div class="article__prices">
            <span v-if="amount > 0" class="article__amount">{{ amount }}x</span>
            <span class="article__price">{{
                toCurrency(price, $store.state.general.language)
            }}</span>
        </div>
        <div class="article__buttons">
            <button
                class="article__button article__button--plus"
                tabindex="-1"
                @click="changeArticle(true)"
            >
                <svg
                    class="article__add"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.42859 6.48975L11.6327 6.48975"
                        stroke="black"
                        stroke-width="1.40625"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M6.53064 1.3877L6.53064 11.5918"
                        stroke="black"
                        stroke-width="1.40625"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
            <button
                class="article__button article__button--minus"
                tabindex="-1"
                @click="changeArticle(false)"
            >
                <svg
                    class="article__substract"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.42859 6.48975L11.6327 6.48975"
                        stroke="black"
                        stroke-width="1.40625"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import "./article.scss";
import Modal from "@/components/Modal/Modal.vue";
import { useGtm } from "@gtm-support/vue-gtm";

export default {
    name: "Article",
    props: {
        icon: String,
        title: String,
        help_text: String,
        description: String,
        price: Number,
        id: String,
        amount: Number,
        image: String,
        isAdditional: Boolean,
        park_name: String
    },
    components: {
        Modal
    },
    computed: {
        classes() {
            let classes = "article";
            if (!this.image) {
                classes += " article--small";
            }
            if (this.amount > 0) {
                classes += " article--selected";
            }
            return classes;
        }
    },
    methods: {
        changeArticle: async function(add) {
            const articles = [...this.$store.state.order.articles, ...this.$store.getters.getAdditionalArticles(this.$store.state.datetime.date)]
            const article = articles.find(t => t.id && t.id === this.id)
            const amount = article.amount

            let changeArticleAmount = "changeArticleAmount";

            if (this.isAdditional) {
                changeArticleAmount = "changeAdditionalArticleAmount";
            }

            await this.$store.commit(changeArticleAmount, {
                article_id: this.id,
                add: add
            });
            await this.$store.commit("setCouponCodes", []);
            await this.$store.commit("setDiscounts", []);

            const gtm = useGtm();

            if(!gtm || !article) {
                return;
            }

            const newAmount = Math.max(
                add ? amount + 1 : amount - 1,
                0
            );
            const _price = this.price.toFixed(2);

            // Don't track when nothing changed.
            if (newAmount === amount) {
                return;
            }

            gtm.trackEvent({
                event: add ? "add_to_cart" : "remove_from_cart",
                value: _price,
                items: [
                    {
                        item_id: this.id,
                        item_name: this.title?.toLowerCase(),
                        item_brand: this.park_name?.toLowerCase() || 'artis_park',
                        item_category: "add_on",
                        item_category2: null,
                        price: _price,
                        quantity: "1",
                        discount: null,
                        coupon: null
                    }
                ]
            });
        },
        open_modal: function(id) {
            const modal = document.querySelector(`.modal#modal-article-${id}`);
            if (modal) {
                modal.classList.add("modal--open");
            }
        }
    }
};
</script>
