<template>
    <div class="feature-group">
        <Feature v-for="f in features" :key="f.guid" :id="f.guid" :slug="f.slug" :text="f.text" :color="f.color" :icon="f.icon" />
    </div>
</template>

<script>
import "./feature-group.scss"

import Feature from '@/components/Feature/Feature'

export default {
    name: "FeatureGroup",
    props: {
        features: Array
    },
    components: {
        Feature
    }
}
</script>
