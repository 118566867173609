<template>
    <layout-default>
        <div class="date">
            <Back />
            <Breadcrumbs :mobile="false" />
            <Intro
                :title="$store.state.general.street.date_view.title"
                :park_name="$store.getters.selectedVenue.park.name"
            />
            <a id="date-selection"></a>
            <flat-pickr
                v-model="date"
                :config="config"
                class="form-control hidden"
                placeholder="Select date"
                :events="eventsToBeEmitted"
                name="date"
                @on-change="updateDate"
                @on-ready="ready"
                @on-month-change="getSoldOut"
                @on-day-create="dayCreate"
            ></flat-pickr>
            <Intro
                :title="$store.state.general.street.date_view.time_title"
                :dark="true"
            />
            <a id="ticket-time-selection"></a>
            <Select
                :upsell="false"
                :options="$store.state.datetime.timeslots"
            />
            <More v-if="$store.state.general.street.date_view.time_more_info" :text="$store.state.general.street.date_view.time_more_info.more" modal_id="modal-date-1"/>
            <template v-if="hasUpsellTickets">
                <IntroImage
                    :title="
                        $store.state.general.street.date_view.upsell_time_title
                    "
                    :park_name="$store.getters.selectedVenue.upsell_park_name"
                    :date="true"
                    :small_bottom="true"
                    :background_image="
                        $store.getters.selectedVenue.upsell_image
                    "
                />
                <a id="upsel-time-selection"></a>
                <Select
                    :upsell="true"
                    :options="$store.state.datetime.upsellTimeslots"
                />
                <More v-if="$store.state.general.street.date_view.upsell_time_more_info" :text="$store.state.general.street.date_view.upsell_time_more_info.more" modal_id="modal-date-2"/>
            </template>
            <ArticleGroup
                v-if="
                    getAdditionalArticles($store.state.datetime.date).length > 0
                "
                :showIntro="true"
                :articles="getAdditionalArticles($store.state.datetime.date)"
                :isAdditional="true"
            />
            <TextBlocks :date="date" />
            <Subtotal
                :button_text="$store.state.general.street.date_view.next_button"
                :next_page="next_page"
                :button_disabled="!$store.getters.isValidDateView"
            />
        </div>
    </layout-default>
    <Modal id="modal-date-1" v-if="$store.state.general.street.date_view.upsell_time_more_info.title" :title="$store.state.general.street.date_view.time_more_info.title" :description="$store.state.general.street.date_view.time_more_info.description" />
    <Modal id="modal-date-2" v-if="$store.state.general.street.date_view.upsell_time_more_info.title" :title="$store.state.general.street.date_view.upsell_time_more_info.title" :description="$store.state.general.street.date_view.upsell_time_more_info.description" />
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import { english } from "flatpickr/dist/l10n/default.js";
import "flatpickr/dist/flatpickr.css";
import "../css/flatpickr.scss";
import { format, isBefore, add } from "date-fns";
import store from "../store";

import { mapGetters } from "vuex";

import LayoutDefault from "@/layouts/LayoutDefault.vue";
import Intro from "@/components/Intro/Intro";
import Select from "@/components/Select/Select";
import ArticleGroup from "@/components/Articles/ArticleGroup";
import More from "@/components/More/More";
import IntroImage from "@/components/Intro/IntroImage";
import Subtotal from "@/components/Price/Subtotal";
import TextBlocks from "@/components/Blocks/TextBlocks";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import Back from "@/components/Back/Back";
import Modal from "@/components/Modal/Modal";
import {useGtm} from "@gtm-support/vue-gtm";

export default {
    name: "Date",
    components: {
        LayoutDefault,
        Intro,
        flatPickr,
        Select,
        More,
        IntroImage,
        Subtotal,
        TextBlocks,
        Breadcrumbs,
        Back,
        ArticleGroup,
        Modal,
    },
    beforeRouteEnter(to, from, next) {
        const optionSlug = to.params.optionSlug
        const option = store.state.general.street.options.find(o => o.slug && o.slug === optionSlug)
        const optionGuid = option?.guid || next({name: "Home"});
        store.commit("setOption", optionGuid);

        const venueSlug = to.params.venueSlug
        const venue = option.venues.find(v => v.slug && v.slug === venueSlug)
        const venueGuid = venue?.guid || next({name: "Home"});
        store.commit("setVenue", venueGuid);

        if (!store.getters.hasBoughtItems) {
            next({ name: "TicketsView" });
        } else {
            next();
        }
    },
    async beforeCreate() {
        try {
            await this.imageLoader(
                this.$store,
                this.$store.getters.selectedVenue.background_image
            );
        } catch (error) {
            console.log(error);
        }
        this.$store.commit("setBackgroundImageHideOnMobile", false);
        this.$store.commit("setBackgroundImageSmall", true);
        this.$store.commit("showFooter", true);
        this.$store.commit("showBreadcrumbs", true);
        this.$store.commit("setBreadcrumbPage", "date");
        this.$store.commit("resetSoldOut");
    },
    beforeUnmount() {
        this.$store.commit("setMainAnimations", false);
    },
    data() {
        const todayString = format(new Date(), "yyyy-MM-dd");

        let locale = english;
        locale.firstDayOfWeek = 1;
        if (this.$store.state.general.language == "nl") {
            locale = Dutch;
        }

        return {
            date: this.$store.state.datetime.date,
            eventsToBeEmitted: [
                "onReady",
                "onChange",
                "onMonthChange",
                "onDayCreate"
            ],
            config: {
                altFormat: "d-m-Y",
                altInput: true,
                altInputClass: "flatpickr-input",
                inline: true,
                dateFormat: "Y-m-d",
                minDate: todayString,
                disalbe: this.$store.state.datetime.sold_out,
                locale: locale, // locale for this instance only
                monthSelectorType: "static",
                nextArrow:
                    '<svg viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 6.5H15" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.27274 1L15 6.5L9.27274 12" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                prevArrow:
                    '<svg viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 6.5H1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.72726 12L1 6.5L6.72726 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            }
        };
    },
    computed: {
        ...mapGetters(["hasUpsellTickets", "getAdditionalArticles"])
    },
    methods: {
        ready: async function(selectedDates, dateStr, instance) {
            dateStr = await this.getNextAvailable(selectedDates, dateStr, instance);
            this.$store.commit("setDate", dateStr);
            this.getTimePeriods(dateStr);
        },
        updateDate: function(_selectedDates, dateStr) {
            this.$store.commit("setTime", null);
            this.$store.commit("setExpositionPeriodGuid", []);
            this.$store.commit("setUpsellTime", null);
            this.$store.commit("setUpsellExpositionPeriodGuid", []);
            this.$store.commit("setDate", dateStr);
            this.getTimePeriods(dateStr);
        },
        getTimePeriods: function(dateStr) {
            const expositions = this.$store.getters
                .getSelectedExpositionGuidsWithAmount;
            if (expositions.length > 0 && dateStr != null) {
                this.$store.dispatch("getTimePeriods", {
                    from: dateStr,
                    expositions: expositions
                });
            }
            const upsellExpositions = this.$store.getters
                .getSelectedUpsellExpositionGuidsWithAmount;
            if (upsellExpositions.length > 0 && dateStr != null) {
                this.$store.dispatch("getUpsellTimePeriods", {
                    from: dateStr,
                    expositions: upsellExpositions
                });
            }
        },
        getFrom: function(instance) {
            const today = new Date();
            const currentMonth = instance.currentMonth;
            let day = 1;
            if (today.getMonth() == currentMonth) {
                day = today.getDate();
            }
            const zeroPad = (num, places) => String(num).padStart(places, "0");
            return `${instance.currentYear}-${zeroPad(
                currentMonth + 1,
                2
            )}-${zeroPad(day, 2)}`;
        },
        getSoldOut: function(
            _selectedDates,
            _dateStr,
            instance
        ) {
            const from = this.getFrom(instance)
            this.$store.dispatch("getSoldOutDates", {
                instance: instance,
                from: from,
                expositionIds: this.$store.getters.getAllExpositionGuids,
            });
        },
        getNextAvailable: async function(
            _selectedDates,
            dateStr,
            instance) {
            const from = this.getFrom(instance)
            await this.$store.dispatch("getSoldOutDates", {
                instance: instance,
                from: from,
                expositionIds: this.$store.getters.getAllExpositionGuids,
            });
            if (dateStr && !this.$store.getters.isSoldout(dateStr)) {
                return new Promise(resolve => {
                    resolve(dateStr);
                });
            }

            let possibleDate = new Date();

            // Check that the selected date is a valid option.
            let checkingMonth = instance.currentMonth;
            let tryNext = true;
            let x_months_later = add(new Date(), { months: 6 });
            while (tryNext && isBefore(possibleDate, x_months_later)) {
                let possibleDateString = format(possibleDate, "yyyy-MM-dd");
                if (!this.$store.getters.isSoldout(possibleDateString)) {
                    tryNext = false;
                    this.$store.commit('setDate', possibleDateString);
                    instance.setDate(possibleDateString, true);
                } else {
                    possibleDate = add(possibleDate, { days: 1 });
                    if(possibleDate.getMonth() + 1 !== checkingMonth) {
                        await this.$store.dispatch("getSoldOutDates", {
                            instance: instance,
                            from: format(possibleDate, "yyyy-MM-dd"),
                            expositionIds: this.$store.getters.getAllExpositionGuids,
                        });
                        checkingMonth = possibleDate.getMonth() + 1
                    }
                }
            }

            return new Promise(resolve => {
                const dd = String(possibleDate.getDate()).padStart(2, "0");
                const mm = String(possibleDate.getMonth() + 1).padStart(2, "0"); //January is 0!
                const yyyy = possibleDate.getFullYear();
                resolve(`${yyyy}-${mm}-${dd}`);
            });
        },
        dayCreate: function(_dObj, _dStr, _fp, dayElem) {
            if (dayElem.classList.contains("flatpickr-disabled")) {
                dayElem.setAttribute(
                    "title",
                    "deze dag is (nog) niet beschikbaar"
                );
            }
        },
        next_page: async function() {
            if (!this.$store.getters.isValidDateView) {
                return;
            }

            const optionSlug = this.$store.getters.selectedOption.slug;
            const venueSlug = this.$store.getters.selectedVenue.slug;

            await this.$router.push({name: "Payment", params: {optionSlug, venueSlug}});

            const gtm = useGtm();

            if(!gtm) {
                return;
            }

            gtm.trackEvent({
                event: "checkout",
                checkout_funnel_name: this.$store.getters.getGTMCheckoutFunnelName,
                checkout_stage_number: "4",
                checkout_stage_name: "datum_&_tijd",
                visit_date_chosen_1: this.$store.getters.getGTMDateTime,
                value: String(this.$store.getters.getGTMItemValue),
                items: this.$store.getters.getGTMItems,
            })
        }
    }
};
</script>
