<template>
    <layout-default>
        <div class="home">
            <HomeIntro :title="$store.state.general.street.title" :intro="$store.state.general.street.description" />
            <div :class="{ 'animation-move-up' : $store.state.general.mainAnimations }">
                <FeatureGroup :features="$store.state.general.street.options" />
            </div>
            <div :class="{ 'animation-fade-in' : $store.state.general.mainAnimations }">
                <More :text="$store.state.general.street.more_info.more" :big=true />
            </div>
        </div>
    </layout-default>
    <Modal id="modal-home" v-if="$store.state.general.street.more_info.title" :title="$store.state.general.street.more_info.title" :description="$store.state.general.street.more_info.description" />
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import FeatureGroup from '@/components/Feature/FeatureGroup'
import More from '@/components/More/More'
import HomeIntro from '@/components/Intro/HomeIntro'
import Modal from "@/components/Modal/Modal"
import {useGtm} from "@gtm-support/vue-gtm";

export default {
    name: 'Home',
    async beforeCreate() {
        await this.imageLoader(this.$store, this.$store.state.general.street.background_image);
        this.$store.commit("setBackgroundImageHideOnMobile", false);
        this.$store.commit("setBackgroundImageSmall", false);
        this.$store.commit("showFooter", false);
        this.$store.commit("showBreadcrumbs", false);
        this.$store.commit("setBreadcrumbPage", "");

        const gtm = useGtm();

        if(!gtm) {
            return;
        }

        gtm.trackEvent({
           "event": "page_view",
           "page_type": "checkout",
           "platform": "web",
           "page_referrer": sessionStorage.getItem("referrer") || null,
           "page_layout": window.matchMedia('(min-width: 768px)').matches ? "desktop" : "mobile",
           "environment": "prod",
           "platform_language_code": this.$store.state.general.language,
        });
    },
    beforeUnmount() {
        this.$store.commit("setMainAnimations", false);
    },
    components: {
        LayoutDefault,
        FeatureGroup,
        More,
        HomeIntro,
        Modal,
    }
}
</script>
