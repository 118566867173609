<template>
    <div class="loading-button" v-bind:class="{ 'loading-button--disabled' : disabled }" data-progress-style="fill-top">
        <div class="loading-button__button">{{ text }}</div>
        <div class="loading-button__progress"></div>
    </div>
</template>

<script>
import "./loading-button.scss"

export default {
    name: "LoadingButton",
    props: {
        text: String,
        disabled: Boolean,
    },
}
</script>
