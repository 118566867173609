<template>
    <div class="language-switch" v-bind:class="{ 'language-switch--back' : $store.state.general.back_page }">
        <img v-if="$store.state.general.language === 'en'" class="language-switch__chosen" src="../../assets/uk-flag.svg" alt="language" v-on:click="show_languages()">
        <img v-if="$store.state.general.language === 'nl'" class="language-switch__chosen" src="../../assets/dutch-flag.svg" alt="language" v-on:click="show_languages()">
        <div class="language-switch__options">
            <div class="langauge-switch__header">
                <span class="langauge-switch__header-text">{{ $store.state.general.street.general_text.language_switch }}</span>
                <img class="langauge-switch__header-image" src="../../assets/close.svg" alt="close" v-on:click="close_languages()">
            </div>
            <div class="language-switch__options-container">
                <a class="language-switch__option" v-bind:class="{ 'language-switch__option--selected' : $store.state.general.language === 'en' }" v-on:click="close_languages('en')">
                    <img class="language-switch__option-image" src="../../assets/uk-flag.svg" alt="english">
                    <span class="language-switch__option-text">{{ $store.state.general.street.general_text.english }}</span>
                </a>
                <a class="language-switch__option" v-bind:class="{ 'language-switch__option--selected' : $store.state.general.language === 'nl' }" v-on:click="close_languages('nl')">
                    <img class="language-switch__option-image" src="../../assets/dutch-flag.svg" alt="dutch">
                    <span class="language-switch__option-text">{{ $store.state.general.street.general_text.dutch }}</span>
                </a>
            </div>
        </div>
        <svg class="language-switch__back" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" v-on:click="go_back()">
            <path d="M15 6.5H1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.72726 12L1 6.5L6.72726 1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
</template>

<script>
import "./language-switch.scss"

export default {
    name: "LanguageSwitch",
    created() {
        this.unwatchLanguageChange = this.$store.watch(
            (state) => state.general.language,
            () => {
                this.$store.dispatch('getFastlaneStreet', false);
            },
        );
    },
    beforeUnmount() {
        this.unwatchLanguageChange();
    },
    methods: {
        show_languages: function () {
            let options = document.querySelector(".language-switch__options");
            options.classList.add("language-switch__options--open");
            options.style = "animation-name: menu";
        },
        close_languages: function (language) {
            let options = document.querySelector(".language-switch__options");
            options.style = "animation-name: menuReverse";
            setTimeout(() => {
                options.classList.remove("language-switch__options--open");
                options.style = null;
            }, 300);
            if(language) {
                this.$store.commit("setLanguage", language);
            }
        },
        go_back: function () {
            this.$router.go(-1)
        }
    }
}
</script>
