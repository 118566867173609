<template>
    <div class="checkbox-group">
        <template v-if="$store.state.general.language === 'nl'">
            <label class="checkbox">
                <input
                    @change="setNewsletterArtis"
                    type="checkbox"
                    id="1"
                    class="checkbox__input"
                    :checked="$store.state.order.artisNewsletter"
                />
                <span class="checkmark"></span>
                <label class="checkbox__label" for="1">
                    <span class="checkbox__title">{{
                        $store.state.general.street.payment_view
                            .artis_newsletter
                    }}</span>
                    <span class="checkbox__help-text">{{
                        $store.state.general.street.payment_view
                            .artis_newsletter_help
                    }}</span>
                </label>
            </label>
            <label class="checkbox">
                <input
                    @change="setNewsletterMicropia"
                    type="checkbox"
                    id="2"
                    class="checkbox__input"
                    :checked="$store.state.order.micropiaNewsletter"
                />
                <span class="checkmark"></span>
                <label class="checkbox__label" for="2">
                    <span class="checkbox__title">{{
                        $store.state.general.street.payment_view
                            .micropia_newsletter
                    }}</span>
                    <span class="checkbox__help-text">{{
                        $store.state.general.street.payment_view
                            .micropia_newsletter_help
                    }}</span>
                </label>
            </label>
            <label class="checkbox">
                <input
                    @change="setNewsletterGrooteMuseum"
                    type="checkbox"
                    id="3"
                    class="checkbox__input"
                    :checked="$store.state.order.grootemuseumNewsletter"
                />
                <span class="checkmark"></span>
                <label class="checkbox__label" for="3">
                    <span class="checkbox__title">{{
                        $store.state.general.street.payment_view
                            .grootemuseum_newsletter
                    }}</span>
                    <span class="checkbox__help-text">{{
                        $store.state.general.street.payment_view
                            .grootemuseum_newsletter_help
                    }}</span>
                </label>
            </label>
        </template>
        <label class="checkbox">
            <input
                @change="setTermsAndConditions"
                type="checkbox"
                id="agree_terms_and_conditions"
                class="checkbox__input"
                required="required"
                :checked="$store.state.order.agreeTermsAndConditions"
            />
            <span class="checkmark"></span>
            <label class="checkbox__label" for="agree_terms_and_conditions">
                <span
                    class="checkbox__title"
                    v-html="
                        $store.state.general.street.payment_view
                            .terms_and_conditions
                    "
                ></span>
                <span
                    class="checkbox__help-text"
                    v-html="
                        $store.state.general.street.payment_view
                            .terms_and_conditions_help
                    "
                ></span>
            </label>
        </label>
    </div>
</template>

<script>
import "./checkbox-group.scss";
import "./checkbox.scss";

export default {
    name: "CheckboxGroup",
    methods: {
        setNewsletterArtis: function(event) {
            this.$store.commit(
                "setNewsletterArtis",
                event.currentTarget.checked
            );
        },
        setNewsletterMicropia: function(event) {
            this.$store.commit(
                "setNewsletterMicropia",
                event.currentTarget.checked
            );
        },
        setNewsletterGrooteMuseum: function(event) {
            this.$store.commit(
                "setNewsletterGrooteMuseum",
                event.currentTarget.checked
            );
        },
        setTermsAndConditions: function(event) {
            event.currentTarget.parentElement.classList.remove("checkbox--error");
            this.$store.commit(
                "setTermsAndConditions",
                event.currentTarget.checked
            );
        }
    }
};
</script>
