<template>
    <div class="contain">
        <router-view
            v-if="!$store.state.general.isLoading"
            v-slot="{ Component, route }"
        >
            <transition :name="transitionName">
                <component :is="Component" :key="route.path" />
            </transition>
        </router-view>
        <loading :active="true" spinner="bar-fade-scale" color="#FF6700" />
    </div>
</template>

<script>
import "./css/general.scss";

import createPersistedState from "vuex-persistedstate";
import store from "./store";

import { addExpositionPrices, addArticle } from "@/store/functions";
import Loading from "@/components/Loading/Loading";

const supported_languages = ["nl", "en"];

export default {
    name: "App",
    components: {
        Loading
    },
    beforeCreate() {
        createPersistedState({ key: document.body.dataset.guid })(store);
        this.$store.commit("setLoading", false);
        this.backgroundUnwatch = this.$store.watch(
            state => state.general.background_change,
            () => {
                setTimeout(() => {
                    this.$store.commit("setBackgroundChange", false);
                }, 295);
            }
        );
        this.$store.commit("setMainAnimations", true);
        this.$store.commit("setBackgroundChange", false);
        this.$store.commit("setBackgroundImage", null);
        this.animationHasRun = false;

        // Set the user preffered language if no language is set yet.
        let language_set = false;

        // If lang parameter set, overwrite language.
        let searchParams = new URL(document.location).searchParams;
        let lang_param = searchParams.get("lang");
        if (lang_param){
            lang_param = lang_param.toLowerCase();
            // if not changed, set language to true to make selector correct
            if(lang_param === this.$store.state.general.language){
                 language_set = true;
            }
            else if (supported_languages.indexOf(lang_param) > -1){
                 this.$store.commit("setLanguage", lang_param);
                 language_set = true;
                 // reset translations
                 this.$store.commit("setFastlaneStreet", null);
            }
        }

        if (!this.$store.state.general.language) {
            navigator.languages.forEach(language => {
                if (supported_languages.indexOf(language) > -1 && !language_set) {
                    this.$store.commit("setLanguage", language);
                    language_set = true;
                }
            });
        }
        if (!language_set) {
            this.$store.commit("setLanguage", "nl");
        }
    },
    created() {
        this.venueUnwatch = this.$store.watch(
            state => state.general.venue,
            newVenue => {
                // Reset all the values, since we changed venue.
                this.$store.commit("setUpsell", null);
                this.$store.commit("resetExpositionTickets");
                this.$store.commit("resetUpsellExpositionTickets");
                this.$store.commit("resetArticles");
                this.$store.commit("resetAdditionalArticles");
                this.$store.commit("setTime", null);
                this.$store.commit("setExpositionPeriodGuid", []);
                this.$store.commit("setUpsellTime", null);
                this.$store.commit("setUpsellExpositionPeriodGuid", []);
                this.$store.commit("setDate", null);
                this.$store.commit("setDonation", {});
                this.$store.commit("resetDateTime");

                if (newVenue) {
                    // Add current exposition tickets.
                    const currentVenue = this.$store.getters.selectedVenue;
                    this.$store.commit("setHasDate", !!currentVenue.exposition);
                    if (currentVenue.exposition) {
                        addExpositionPrices(
                            currentVenue.exposition,
                            false,
                            this.$store
                        );
                    }

                    // Add other exposition tickets with the same park.
                    this.$store.state.general.street.options.forEach(option => {
                        option.venues.forEach(venue => {
                            if (venue.guid === currentVenue.guid) {
                                return;
                            } else if (
                                venue.park.guid === currentVenue.park.guid
                            ) {
                                if (venue.exposition) {
                                    addExpositionPrices(
                                        venue.exposition,
                                        false,
                                        this.$store
                                    );
                                }
                            }
                        });
                    });

                    // Add the articles.
                    if (currentVenue && currentVenue.articles) {
                        currentVenue.articles.forEach(article =>
                            addArticle(article, this.$store)
                        );
                    }
                }
            }
        );
    },
    mounted() {
        // Store `document.referrer` in session storage.
        // We need to access the referer for GTM events when the purchase is complete.
        // The value of `document.referrer` however, may differ due to the redirect to the Django view (on dev
        // environments).
        // Caching the value improves consistency.
        if(document.referrer) {
            sessionStorage.setItem('referrer', document.referrer)
        }
    },

    beforeUnmount() {
        this.backgroundUnwatch();
        this.venueUnwatch();
    },
    data() {
        return {
            transitionName: ""
        };
    },
    watch: {
        $route(to, from) {
            // Set animation
            const toDepth = to.meta.depth;
            const fromDepth = from.meta.depth;
            this.transitionName = "";
            if (toDepth < fromDepth) {
                this.transitionName = "slide-right";
            } else if (toDepth > fromDepth) {
                this.transitionName = "slide-left";
            }
        }
    }
};
</script>
