<template>
    <div class="animation">
        <div :class="classes">
            <div>
                <MainImage />
                <div class="page-wrapper__content">
                    <nav
                        class="header"
                        :class="{
                            'header--animate':
                                $store.state.general.mainAnimations
                        }"
                    >
                        <LanguageSwitch />
                        <Breadcrumbs :mobile="true" />
                        <Menu />
                    </nav>

                    <div class="main">
                        <div class="container"
                             :class="{
                                'container--scroll':
                                    $store.state.general.show_scroll
                            }"
                             data-simplebar
                             data-simplebar-auto-hide="false"
                             ref="container"
                        >
                            <div class="container__container">
                                <slot />
                            </div>
                            <ScrollHelp />
                            <Footer />
                        </div>
                        <div class="text-selection">
                            <div
                                class="text-selection__item text-selection__smaller"
                                v-bind:class="{
                                    'text-selection__item--active':
                                        textSelected == 'normal'
                                }"
                                @click="makeFontSmaller"
                            >
                                A
                            </div>
                            <div
                                class="text-selection__item text-selection__bigger"
                                v-bind:class="{
                                    'text-selection__item--active':
                                        textSelected == 'big'
                                }"
                                @click="makeFontBigger"
                            >
                                A
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import "../css/animation.scss";
import "../css/container.scss";
import "../css/header.scss";
import "../css/page-wrapper.scss";
import "../css/main.scss";
import "../css/lds.scss";
import "../css/text-selection.scss";

import { mapState } from "vuex";

import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import LanguageSwitch from "@/components/LanguageSwitch/LanguageSwitch";
import Footer from "@/components/Footer/Footer";
import Menu from "@/components/Menu/Menu";
import ScrollHelp from "@/components/ScrollHelp/ScrollHelp";
import MainImage from "@/components/MainImage/MainImage";

function addStyling(styling) {
    let localStyling = document.getElementById("local-styling");
    if (!localStyling) {
        localStyling = document.createElement("style");
        localStyling.id = "local-styling";
        localStyling.type = "text/css";
        if (localStyling.styleSheet) {
            localStyling.styleSheet.cssText = styling;
        } else {
            localStyling.appendChild(document.createTextNode(styling));
        }
        document.getElementsByTagName("head")[0].appendChild(localStyling);
    } else {
        if (localStyling.styleSheet) {
            localStyling.styleSheet.cssText = styling;
        } else {
            localStyling.appendChild(document.createTextNode(styling));
        }
    }
}

export default {
    name: "App",
    components: {
        Breadcrumbs,
        LanguageSwitch,
        Footer,
        Menu,
        ScrollHelp,
        MainImage,
    },
    mounted() {
        setTimeout(() => {
            this.setNodeHeight();
        }, 200);
        this.addScrollEventListener();
    },
    watch: {
        $route() {
            setTimeout(() => {
                this.setNodeHeight();
                this.addScrollEventListener();
            }, 200);
        }
    },
    computed: {
        ...mapState({
            street: state => state.general.street
        }),
        classes() {
            let allClasses = "page-wrapper";
            if (
                this.$store.state.general.background_image &&
                !(
                    this.$store.state.general.background_image_hide_on_mobile &&
                    window.innerWidth < 768
                )
            ) {
                allClasses += " page-wrapper--background-image";
                if (this.$store.state.general.background_image_small) {
                    allClasses += " page-wrapper--darker";
                }
            }
            return allClasses;
        },
        textSelected() {
            if (document.documentElement.style.fontSize == `18px`) {
                return "big";
            } else {
                return "normal";
            }
        }
    },
    ready: function() {
        const container = document.querySelector(".container");
        this.setNodeHeight(container);
    },
    methods: {
        setNodeHeight() {
            let containerContainer = document.querySelector(
                ".container__container"
            );
            let footer = document.querySelector(".footer");
            if (containerContainer) {
                let containerHeight = containerContainer.scrollHeight;
                if (footer) {
                    containerHeight += footer.scrollHeight;
                }
                if (containerHeight > window.innerHeight - 150) {
                    containerHeight = window.innerHeight - 150;
                }
                addStyling(
                    `@media (min-width: 768px) { .container { height: ${containerHeight}px; }}`
                );

                // Change if the scrollHeight should be shown
                this.$store.commit("setShowScroll", false);
                setTimeout(() => {
                    this.checkShowScroll();
                }, 500);
            }
        },
        makeFontSmaller: function() {
            const smaller = document.querySelector(".text-selection__smaller");
            const bigger = document.querySelector(".text-selection__bigger");

            if (!smaller.classList.contains("text-selction__item--active")) {
                smaller.classList.add("text-selection__item--active");
                bigger.classList.remove("text-selection__item--active");
                document.documentElement.style.fontSize = `16px`;
            }
        },
        makeFontBigger: function() {
            const smaller = document.querySelector(".text-selection__smaller");
            const bigger = document.querySelector(".text-selection__bigger");

            if (!bigger.classList.contains("text-selction__item--active")) {
                smaller.classList.remove("text-selection__item--active");
                bigger.classList.add("text-selection__item--active");
                document.documentElement.style.fontSize = `18px`;
            }
        },
        addScrollEventListener: function() {
            const container = document.querySelector(".container");
            let use_upsell = false;
            container.addEventListener("scroll", () => {
                const scroll = document.querySelector(".scroll-help");
                scroll.classList.add("scroll-help--animation");

                // Upsell background image change
                const upsell = document.querySelector(".upsell");
                if (upsell) {
                    const bounding = upsell.getBoundingClientRect();
                    const top = bounding.top;
                    const venue = this.$store.getters.selectedVenue;

                    if (top < container.clientHeight / 2) {
                        if (!use_upsell) {
                            this.imageLoader(this.$store, venue.upsell_image);
                            use_upsell = true;
                        }
                    } else {
                        if (use_upsell) {
                            this.imageLoader(
                                this.$store,
                                venue.background_image
                            );
                            use_upsell = false;
                        }
                    }
                }
            });
        },
        checkShowScroll: function () {
            const content = document.querySelector('.simplebar-content');
            const contentWrapper = document.querySelector('.simplebar-content-wrapper');
            const commit = (exp = content.clientHeight < content.scrollHeight) => {
                if(exp === false) {
                    contentWrapper?.removeEventListener('scroll', commit);
                }

                return this.$store.commit(
                    'setShowScroll',
                    exp
                );
            };

            if (content) {
                commit()
            }

            contentWrapper?.addEventListener('scroll', commit.bind(this, false));
        }
    }
};
</script>
