// In this store all the values will be saved that are send to the backend for the order.
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
