<template>
    <div v-if="showBreadcrumbs" :class="classes">
        <div v-on:click="goto_venue()" :class="venue_classes">
            <svg v-if="venueIcon === 'open'" class="breadcrumbs__icon" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7" cy="7" r="6"/>
            </svg>
            <svg v-if="venueIcon === 'solid'" class="breadcrumbs__icon" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="6" />
            </svg>
            <svg  v-if="venueIcon === 'done'" class="breadcrumbs__icon" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="5.5"/>
                <path d="M8.5 4.5L5.125 7.87482L3.5 6" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ generalText.venue }}
        </div>
        <div class="breadcrumb-divider"></div>
        <div v-on:click="goto_tickets()" :class="ticket_classes">
            <svg v-if="ticketIcon === 'open'" class="breadcrumbs__icon" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7" cy="7" r="6"/>
            </svg>
            <svg v-if="ticketIcon === 'solid'" class="breadcrumbs__icon" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="6" />
            </svg>
            <svg  v-if="ticketIcon === 'done'" class="breadcrumbs__icon" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="5.5"/>
                <path d="M8.5 4.5L5.125 7.87482L3.5 6" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ generalText.tickets }}
        </div>
        <template v-if="$store.state.general.hasDate">
            <div class="breadcrumb-divider"></div>
            <div v-on:click="goto_date()" :class="date_classes">
                <svg v-if="dateIcon === 'open'" class="breadcrumbs__icon" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7" cy="7" r="6"/>
                </svg>
                <svg v-if="dateIcon === 'solid'" class="breadcrumbs__icon" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="6" />
                </svg>
                <svg  v-if="dateIcon === 'done'" class="breadcrumbs__icon" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="5.5"/>
                    <path d="M8.5 4.5L5.125 7.87482L3.5 6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ generalText.date }}
            </div>
        </template>
    </div>
</template>

<script>
import "./breadcrumbs.scss"

import { mapState } from 'vuex'

export default {
    name: "Breadcrumbs",
    props: {
        mobile: Boolean,
    },
    computed: {
        ...mapState({
            showBreadcrumbs: state => state.general.show_breadcrumbs,
            generalText: state => {
                if (state.general.street && state.general.street.general_text) {
                    return state.general.street.general_text
                } else {
                    return {}
                }
            }
        }),
        classes() {
            let classes = "breadcrumbs";
            if(this.mobile) {
                classes += " breadcrumbs--mobile";
            } else {
                classes += " breadcrumbs--desktop";
            }
            return classes;
        },
        venueIcon() {
            if(this.$store.state.general.breadcrumb_page === 'venue') {
                return "solid"
            } else if(this.$store.state.general.breadcrumb_page !== 'venue' ) {
                return "done"
            } else {
                return "open"
            }
        },
        venue_classes() {
            let classes = "breadcrumb-item";
            if(this.$store.state.general.breadcrumb_page === 'venue') {
                classes += " breadcrumb-item--selected";
            }
            if(this.$store.state.general.breadcrumb_page !== 'venue' ) {
                classes += " breadcrumb-item--done";
            }
            return classes;
        },
        ticketIcon() {
            if(this.$store.state.general.breadcrumb_page === 'tickets') {
                return "solid"
            } else if(this.$store.getters.isValidTicketsView) {
                return "done"
            } else {
                return "open"
            }
        },
        ticket_classes() {
            let classes = "breadcrumb-item";
            if(this.$store.state.general.breadcrumb_page === 'tickets') {
                classes += " breadcrumb-item--selected";
            }
            if(this.$store.getters.isValidTicketsView) {
                classes += " breadcrumb-item--done";
            }
            return classes;
        },
        dateIcon() {
            if(this.$store.state.general.breadcrumb_page === 'date') {
                return "solid"
            } else if(this.$store.getters.isValidDateView) {
                return "done"
            } else {
                return "open"
            }
        },
        date_classes() {
            let classes = "breadcrumb-item";

            if(this.$store.state.general.breadcrumb_page === 'date') {
                classes += " breadcrumb-item--selected";
            } else if(this.$store.getters.isValidDateView) {
                classes += " breadcrumb-item--done";
            }
            return classes;
        },
    },
    methods: {
        goto_venue: function () {
            this.$router.push({ name: 'Venue' })
        },
        goto_tickets: function () {
            if(this.$store.getters.isValidTicketsView) {
                this.$router.push({ name: 'TicketsView' })
            }
        },
        goto_date: function () {
            if(this.$store.getters.isValidDateView) {
                this.$router.push({ name: 'Date' })
            }
        },
    }
}
</script>
