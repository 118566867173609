<template>
    <layout-default>
        <div class="tickets-view">
            <Back />
            <Breadcrumbs :mobile=false />
            <Intro :title="introText" :big_spacing=true />
            <TicketGroup v-if="$store.state.order.expositionTickets.length > 0" :park_name="this.$store.getters.selectedVenue.park.name"/>
            <More v-if="$store.state.order.expositionTickets.length > 3" :text="$store.state.general.street.general_text.more_options" :less_text="$store.state.general.street.general_text.less_options" :action="toggle_tickets" icon="more" />
            <Upsell v-if="$store.getters.selectedVenue.upsales.length > 0" modal_id="modal-upsell"/>
            <ArticleGroup
                v-if="$store.state.order.articles.length > 0"
                :showIntro="$store.state.order.expositionTickets.length > 0"
                :articles="$store.state.order.articles"
                :park_name="this.$store.getters.selectedVenue.park.name"
            />
            <Subtotal :button_text="nextButtonText" :button_disabled="!$store.getters.isValidTicketsView" :next_page="next_page" />
        </div>
    </layout-default>
    <Modal id="modal-upsell" v-if="this.$store.getters.selectedVenue?.upsell_more_info?.title" :title="this.$store.getters.selectedVenue.upsell_more_info.title" :description="this.$store.getters.selectedVenue.upsell_more_info.description" />
    <Modal v-bind:key="article.description" v-for="article in $store.state.order.articles" :id="`modal-article-${article.title}`" :title="article.title" :description="article.description" />
</template>

<script>
import store from "../store";
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import Intro from '@/components/Intro/Intro'
import TicketGroup from '@/components/Tickets/TicketGroup'
import More from '@/components/More/More'
import Upsell from '@/components/Upsell/Upsell'
import ArticleGroup from '@/components/Articles/ArticleGroup'
import Subtotal from '@/components/Price/Subtotal'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import Back from '@/components/Back/Back'
import Modal from '@/components/Modal/Modal'
import {useGtm} from "@gtm-support/vue-gtm";

export default {
    name: "TicketsView",
    beforeRouteEnter(to, from, next) {
        const optionSlug = to.params.optionSlug
        const option = store.state.general.street.options.find(o => o.slug && o.slug === optionSlug)
        const optionGuid = option?.guid || next({name: "Home"});
        store.commit("setOption", optionGuid);

        const venueSlug = to.params.venueSlug
        const venue = option.venues.find(v => v.slug && v.slug === venueSlug)
        const venueGuid = venue?.guid || next({name: "Home"});
        store.commit("setVenue", venueGuid);
        next();
    },
    async beforeCreate() {
        const bg = this.$store.getters.getUpsell && this.$store.getters.selectedVenue.upsell_image
            ? this.$store.getters.selectedVenue.upsell_image
            : this.$store.getters.selectedVenue.background_image
        await this.imageLoader(this.$store, bg);
        this.$store.commit("setBackgroundImageHideOnMobile", false);
        this.$store.commit("setBackgroundImageSmall", true);
        this.$store.commit("showFooter", true);
        this.$store.commit("showBreadcrumbs", true);
        this.$store.commit("setBreadcrumbPage", "tickets");
    },
    beforeUnmount() {
        this.$store.commit("setMainAnimations", false);
    },
    components: {
        LayoutDefault,
        Intro,
        TicketGroup,
        More,
        Upsell,
        ArticleGroup,
        Subtotal,
        Breadcrumbs,
        Back,
        Modal,
    },
    methods: {
        next_page: async function() {
            if(this.$store.getters.isValidTicketsView) {
                const optionSlug = this.$store.getters.selectedOption.slug;
                const venueSlug = this.$store.getters.selectedVenue.slug;

                if(this.$store.state.general.hasDate) {
                    await this.$router.push({ name: "Date", params: { optionSlug, venueSlug} })
                } else {
                    await this.$router.push({ name: "Payment" })
                }
            }

            const gtm = useGtm()

            if(!gtm) {
                return;
            }

            gtm.trackEvent({
                event: "checkout",
                checkout_funnel_name: this.$store.getters.getGTMCheckoutFunnelName,
                checkout_stage_number: "3",
                checkout_stage_name: "tickets",
                value: String(this.$store.getters.getGTMItemValue),
                items: this.$store.getters.getGTMItems,
            })
        },
        toggle_tickets: function(more) {
            more.previousElementSibling.classList.toggle("ticket-group--all");
            more.classList.toggle('more--open');
        }
    },
    computed: {
        nextButtonText() {
            if(this.$store.state.general.hasDate) {
                return this.$store.getters.selectedVenue.next_button;
            } else {
                return this.$store.getters.selectedVenue.next_button_alt;
            }
        },
        introText() {
            if (this.$store.getters.hasBoughtItems) {
                return this.$store.getters.selectedVenue.product_selected_title
            }
            return this.$store.getters.selectedVenue.title;
        },
    },
}
</script>
