<template>
    <div
        class="input"
        v-bind:class="{ 'input--filled' : $store.state.order.country.scope_id }"
        @keydown.stop.prevent.down="keyDown($event)"
        @keydown.stop.prevent.up="keyUp($event)"
        @keydown.stop.prevent.enter="keySelect($event)"
    >
        <input class="input__hidden" type="hidden" value="" />
        <input
            class="input__input"
            @focus="extended_open_dropdown($event)"
            @focusout="clear($event)"
            @input.stop="search($event)"
            :value="$store.state.order.country.name"
        >
        <label class="input__label">{{ $store.state.general.street.general_text.country }}</label>
        <div class="input__dropdown">
            <div
                v-for="country in $store.state.general.countries"
                :key="country.scope_id"
                class="input__item"
                :data-country="JSON.stringify(country)"
                v-bind:class="{ 'input__item--selected' : country.scope_id === $store.state.order.country.scope_id }"
                @click.stop.prevent="close_dropdown(country, $event)"
            >
                {{ country.name }}
            </div>
        </div>
    </div>
</template>

<script>
import "../Form/input.scss"

export default {
    name: "Countries",
    created() {
        this.countryList = this.$store.watch(
            (state) => state.general.countries,
            (newCountries) => {
                if(!this.$store.state.order.country.scopeId && newCountries.length > 0 && this.$store.state.general.language === "nl") {
                    this.selectedCountry(newCountries[0]);
                }
            },
        );
    },
    beforeUnmount() {
        this.countryList();
    },
    methods: {
        change(event) {
            setTimeout(() => {
                event.target.value = this.$store.state.order.country.name || "";
            }, 50);
        },
        search(event) {
            const value = event.currentTarget.value.toUpperCase();
            const dropdown = event.currentTarget.parentElement.querySelector(".input__dropdown");
            dropdown.classList.add("input__dropdown--open");
            const items = dropdown.querySelectorAll(".input__item");
            [...items].forEach(item => {
                if(item.innerText.toUpperCase().includes(value)) {
                    item.classList.remove("input__item--hidden");
                } else {
                    item.classList.add("input__item--hidden");
                }
            });
        },
        toggle_dropdown(event) {
            event.preventDefault();
            event.stopPropagation();

            let dropdown = event.currentTarget.parentElement.querySelector('.input__dropdown');
            dropdown.classList.toggle("input__dropdown--open");
            this.search({currentTarget: event.currentTarget.parentElement.querySelector(".input__hidden")});
        },
        extended_open_dropdown(event) {
            this.toggle_dropdown(event);
            let dropdown = event.currentTarget.parentElement.querySelector('.input__dropdown');
            dropdown.scrollIntoView({behavior: "smooth", block:  'nearest'});
            let currentSelected = event.currentTarget.parentElement.querySelector('.input__item--selected');
            if(currentSelected) {
                currentSelected.scrollIntoView({behavior: "smooth", block:  'nearest'});
            }
        },
        keySelect(event) {
            let currentHighlight = event.currentTarget.querySelector('.input__item--highlight');
            if (currentHighlight) {
                let countryData = JSON.parse(currentHighlight.dataset.country);
                this.close_dropdown(countryData, event);
            }
        },
        keyUp(event) {
            let currentHighlight = event.currentTarget.querySelector('.input__item--highlight');
            let currentSelected = event.currentTarget.querySelector('.input__item--selected');
            if (currentHighlight) {
                let previousHighlight = currentHighlight.previousSibling;
                if(previousHighlight.nodeType === 1) {
                    previousHighlight.classList.add("input__item--highlight");
                    previousHighlight.scrollIntoView({behavior: "smooth", block:  'nearest'});
                    currentHighlight.classList.remove("input__item--highlight");
                }
            } else if (currentSelected) {
                let previousHighlight = currentSelected.previousSibling;
                if(previousHighlight.nodeType === 1) {
                    previousHighlight.classList.add("input__item--highlight");
                    previousHighlight.scrollIntoView({behavior: "smooth", block:  'nearest'});
                }
            }
        },
        keyDown(event) {
            let dropdown = event.currentTarget.querySelector('.input__dropdown');
            if (dropdown.classList.contains("input__dropdown--open")) {
                let currentHighlight = event.currentTarget.querySelector('.input__item--highlight');
                let currentSelected = event.currentTarget.querySelector('.input__item--selected');
                if (currentHighlight) {
                    let nextHighlight = currentHighlight.nextSibling;
                    if(nextHighlight.nodeType === 1) {
                        nextHighlight.classList.add("input__item--highlight");
                        nextHighlight.scrollIntoView({behavior: "smooth"});
                        currentHighlight.classList.remove("input__item--highlight");
                    }
                } else if (currentSelected) {
                    let nextHighlight = currentSelected.nextSibling;
                    if(nextHighlight.nodeType === 1) {
                        nextHighlight.classList.add("input__item--highlight");
                        nextHighlight.scrollIntoView({behavior: "smooth", block:  'nearest'});
                    }
                } else {
                    let firstItem = event.currentTarget.querySelector('.input__item');
                    firstItem.classList.add("input__item--highlight");
                }
            } else {
                this.toggle_dropdown(event);
            }
        },
        selectedCountry(selected) {
            this.$store.commit("setCountry", selected);
        },
        close_dropdown(selected, event) {
            this.selectedCountry(selected);

            let queryable = null;
            if (event.currentTarget.classList.contains("input")) {
                queryable = event.currentTarget;
            } else {
                queryable = event.currentTarget.parentElement.parentElement;
            }

            let dropdown = queryable.querySelector('.input__dropdown');
            dropdown.classList.remove("input__dropdown--open");
        },
        clear(event) {
            const currentTarget = event.currentTarget.parentElement;
            setTimeout(() => {
                let dropdown = currentTarget.querySelector('.input__dropdown');
                dropdown.classList.remove("input__dropdown--open");
                this.change(event);
            }, 500);
        },
    }
}
</script>
