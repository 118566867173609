import axios from "axios";

axios.defaults.headers.common[
    "Authorization"
] = `Token ${document.body.dataset.token}`;

let expositionQueryString = expositions => {
    let querystring = "";
    expositions.forEach((exposition, index) => {
        querystring += `expositions={"amount":${exposition.amount},"guid": "${exposition.guid}"}`;
        if (index + 1 < expositions.length) {
            querystring += "&";
        }
    });
    return querystring;
};

let expositionQueryStringSoldOut = expositionIds => {
    let querystring = "";
    expositionIds.forEach((expositionId, index) => {
        querystring += `exposition_ids=${expositionId}`;
        if (index + 1 < expositionIds.length) {
            querystring += "&";
        }
    });
    return querystring;
};

let buildBasket = store => {
    let data = {
        given_price: parseInt(store.getters.getSubtotal * 100),
        street: store.state.general.street.guid,
        venue: store.state.general.venue,
        payment_option: store.state.order.paymentOption.id,
        email: store.state.order.email,
        first_name: store.state.order.firstName,
        last_name: store.state.order.lastName,
        country: store.state.order.country.scope_id,
        artis_newsletter: store.state.order.artisNewsletter,
        micropia_newsletter: store.state.order.micropiaNewsletter,
        grootemuseum_newsletter: store.state.order.grootemuseumNewsletter,
        agree_terms_and_conditions: store.state.order.agreeTermsAndConditions,
        coupon_codes: [...store.state.order.couponCodes]
    };

    if (store.state.order.donation) {
        data.donation_guid = store.state.order.donation.guid;
        data.donation_price = store.state.order.donationPrice;
    }

    if (store.state.order.issuer) {
        data.issuer = store.state.order.issuer.id;
    }

    let expositionTickets = [];
    store.getters.selectedTickets.forEach(ticket => {
        expositionTickets.push({
            amount: ticket.amount,
            exposition_period_guid: store.state.order.expositionPeriodGuid.find(
                period => period.expositionId === ticket.exposition_id
            ).period_id,
            exposition: ticket.exposition_id,
            exposition_price: ticket.id
        });
    });

    store.getters.selectedUpsellTickets.forEach(ticket => {
        expositionTickets.push({
            amount: ticket.amount,
            exposition_period_guid: store.state.order.upsellExpositionPeriodGuid.find(
                period => period.expositionId === ticket.exposition_id
            ).period_id,
            exposition: ticket.exposition_id,
            exposition_price: ticket.id
        });
    });

    let articles = [];
    store.getters.selectedArticles.forEach(article => {
        articles.push({
            amount: article.amount,
            article: article.id
        });
    });

    store.getters
        .selectedAdditionalArticles(store.state.datetime.date)
        .forEach(article => {
            articles.push({
                amount: article.amount,
                article: article.id
            });
        });

    if (expositionTickets) {
        data.exposition_orderlines = expositionTickets;
    }

    if (articles) {
        data.article_orderlines = articles;
    }

    return data;
};

export default {
    async getStreet(language) {
        const result = await axios.get(
            `/${language}/api/streets/${document.body.dataset.guid}/`
        );

        if (result.status === 200) {
            return result.data;
        } else {
            console.error(result);
            return null;
        }
    },
    async getSoldOutDates(language, expositionIds, from) {
        const result = await axios.get(
            `/${language}/api/expositions/soldout/?from=${from}&${expositionQueryStringSoldOut(
                expositionIds
            )}`,
            { timeout: 10000 }
        );

        if (result.status === 200) {
            return result.data;
        } else {
            console.error(result);
            return null;
        }
    },
    async getTimeSlots(language, expositions, from) {
        const result = await axios.get(
            `/${language}/api/expositions/timeslots/?from=${from}&${expositionQueryString(
                expositions
            )}`,
            { timeout: 10000 }
        );

        if (result.status === 200) {
            return result.data;
        } else {
            console.error(result);
            return null;
        }
    },
    async getPaymentOptions(language) {
        const result = await axios.get(
            `/${language}/api/orders/payment-options/`
        );
        if (result.status === 200) {
            return result.data;
        } else {
            console.error(result);
            return null;
        }
    },
    async getCurrentPrices(language, from_date, exposition_id) {
        const result = await axios.get(
            `/${language}/api/expositions/prices/?from_date=${from_date}&exposition_id=${exposition_id}`
        );

        if (result.status === 200) {
            return result.data;
        } else {
            console.error(result);
            return null;
        }
    },
    async getCountries(language) {
        const result = await axios.get(`/${language}/api/general/countries/`);

        if (result.status === 200) {
            return result.data;
        } else {
            console.error(result);
            return null;
        }
    },
    async couponCheck(language, store, coupon) {
        const data = buildBasket(store);

        if (coupon) {
            data.coupon_codes.push(coupon);
        }

        try {
            const result = await axios.post(
                `/${language}/api/orders/coupon_validate/`,
                data
            );

            if (result.status === 200) {
                return { discounts: result.data, error: null };
            } else {
                return { discounts: null, error: result };
            }
        } catch (err) {
            return { discounts: null, error: err.response };
        }
    },
    async createOrder(language, store) {
        const data = buildBasket(store);

        data.discount_price = store.getters.getDiscountPrice;

        try {
            const result = await axios.post(
                `/${language}/api/orders/create/`,
                data
            );

            if (result.status === 201) {
                return { data: result.data, error: null };
            } else {
                return { data: null, error: result };
            }
        } catch (err) {
            return { data: null, error: err.response };
        }
    }
};
