<template>
    <div class="blocks blocks--grid">
        <a
            v-for="donation in $store.state.general.street.donation_articles"
            :key="donation.guid"
            class="donations"
            v-bind:class="{
                'donations--active':
                    $store.state.order.donation.guid === donation.guid
            }"
            @click="setDonation(donation, $event)"
        >
            <span class="donations__text-light">{{
                toCurrency(
                    Number(donation.price),
                    $store.state.general.language
                )
            }}</span>
        </a>
        <a
            class="donations"
            v-bind:class="{
                'donations--active':
                    $store.state.order.donation.guid ===
                    $store.state.general.street.open_donation_article.guid
            }"
            @click="
                customDonation(
                    $store.state.general.street.open_donation_article,
                    $event
                )
            "
        >
            <span class="donations__text-light donations__text-light--custom">{{
                $store.state.general.street.general_text.other
            }}</span>
            <input
                class="donations__input"
                type="number"
                min="1.00"
                step="1.00"
                :value="customDonationPrice"
                @change="updateDonation($event)"
            />
            <svg
                class="donations__close"
                @click="clearCustomDonation($event)"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M15.625 4.375L4.375 15.625"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M15.625 15.625L4.375 4.375"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </a>
    </div>
</template>

<script>
import "../Blocks/blocks.scss";
import "./donations.scss";
import { useGtm } from "@gtm-support/vue-gtm";

export default {
    name: "Donations",
    computed: {
        customDonationPrice() {
            return this.$store.state.order.donationPrice;
        }
    },
    methods: {
        setDonation(donation, event) {
            event.preventDefault();
            this.gtmRemoveCurrentDonation();

            const add = !event.currentTarget.classList.contains(
                "donations--active"
            );

            if (add) {
                this.$store.commit("setDonation", donation);
                this.$store.commit("setDonationPrice", Number(donation.price));
                this.gtmAddDonation(donation);
            } else {
                this.$store.commit("setDonation", {});
                this.$store.commit("setDonationPrice", 0);
            }
        },
        updateDonation(event) {
            this.gtmRemoveCurrentDonation();

            const amount = Number(event.currentTarget.value);
            const donation = this.$store.getters.getDonation;
            this.$store.commit("setDonationPrice", amount);

            this.gtmAddDonation(donation, amount);
        },
        customDonation(donation, event) {
            event.preventDefault();
            this.$store.commit("setDonation", donation);
            event.currentTarget.querySelector(".donations__input").focus();
        },
        clearCustomDonation(event) {
            event.preventDefault();
            event.stopPropagation();

            this.gtmRemoveCurrentDonation();

            this.$store.commit("setDonationPrice", 0);
            this.$store.commit("setDonation", {});
        },
        gtmRemoveCurrentDonation() {
            const donation = this.$store.getters.getDonation;
            const donationPrice = this.$store.getters.getDonationPrice;
            const gtm = useGtm();

            // No donation set.
            if (!gtm || (!donation?.guid && !parseFloat(donationPrice))) {
                return;
            }

            gtm.trackEvent({
                event: "remove_from_cart",
                value: Number(donationPrice).toFixed(2),
                items: [
                    {
                        item_id: donation?.guid,
                        item_name: "donatie",
                        item_brand:
                            (this.$store.getters.selectedVenue?.park.name || "").toLowerCase(),
                        item_category: "donation",
                        item_category2: null,
                        price: Number(donationPrice).toFixed(2),
                        quantity: "1",
                        discount: null,
                        coupon: null
                    }
                ]
            });
        },
        gtmAddDonation(donation, amount = donation.price) {
            const value = parseFloat(amount).toFixed(2);

            const gtm = useGtm();

            if(!gtm) {
                return;
            }

            gtm.trackEvent({
                event: "add_to_cart",
                value: value,
                items: [
                    {
                        item_id: donation.guid,
                        item_name: "donatie",
                        item_brand:
                            (this.$store.getters.selectedVenue?.park.name || "").toLowerCase(),
                        item_category: "donation",
                        item_category2: null,
                        price: value,
                        quantity: "1",
                        discount: null,
                        coupon: null
                    }
                ]
            });
        }
    }
};
</script>
